@import "./GridComponents.scss";

.editableField {
    min-height: 1.5rem;
    border-radius: 6px;

    &:hover {
        box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.4), inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    }
}

.display {
    min-height: 1.5rem;
}

.contents {
    max-width: $column-max-width;
    overflow: hidden;
    text-overflow: ellipsis;
}
