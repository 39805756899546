@import "../../../scss/variables.scss";

.container {
    min-width: 520px;
    max-width: 520px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.closeButton {
    height: 30px;
    width: 30px;
    border: 1px solid white;
}
