html {
  font-family: 'PTSans', sans-serif;
  font-weight: normal;
  color: $mia-text;
  line-height: 16px;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
button {
  font-family: 'PTSans', sans-serif;
}

// Header Styles
h1 {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 1.2;
  color: #55697a;
}

h2 {
  font-size: 30px;
  line-height: 30px;
  color: #8f92a6;
}
h3 {
  font-size: 18px;
  line-height: 18px;
  color: $mia-greyer;
}

h4 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: $mia-text;
}

h5 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 1.2;
}

h6 {
  font-size: 12px;
  line-height: 12px;
}

.shout {
  text-transform: uppercase;
}

p {
  font-size: 14px;
  line-height: 14px;
  color: $mia-text;
}

.money {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

.tight {
  padding-bottom: 0.1em;
  margin-bottom: 0;
}

p {
  margin-bottom: $pad;
}

// Text Styles
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}

.btn {
  font-size: 14px;
}
