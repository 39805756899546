@import "../../../scss/variables.scss";

.containerBlue {
    border: 2px solid $invevo-blue;

    &.selected {
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        background-color: $invevo-mid-blue;
    }
}

.containerGrey {
    border: 2px solid $invevo-light-grey;

    &.selected {
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        background-color: $invevo-light-grey;
    }
}
