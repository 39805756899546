@import "../../../scss/variables.scss";

.button {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.4),
        -5px -5px 8px rgba(255, 255, 255, 0.4);
    border-radius: 7px;
    border: 1px solid $invevo-blue;

    .icon {
        border-radius: 6px 0px 0px 6px;
        color: white;
        background-color: #149edc;
    }

    .label-white {
        border-radius: 0px 7px 7px 0px;
        color: $invevo-text;
        background-color: white;
    }

    .label-blue {
        border-radius: 0px 7px 7px 0px;
        color: white;
        background-color: $invevo-blue;
    }

    &:hover {
        .icon {
            color: $invevo-blue;
            background-color: #ddf1fa;
        }

        .label-white {
            color: white;
            background-color: $invevo-blue;
        }

        .label-blue {
            color: $invevo-blue;
            background-color: white;
        }
    }
}