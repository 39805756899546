.dashboardsContainer {
    box-shadow: 0 0 0.5em 0.25em rgba(50, 50, 50, 0.3);
    min-width: 250px;
    max-width: 300px;
}

.selectedCard {
    background-color: #1874AB;
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
}