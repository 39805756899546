@import "../../../../../../scss/variables.scss";

.card {
    background-color: $invevo-background-grey;
}

.darkCard {
    background-color: $invevo-light-blue;
}

.verticalDivider {
    border-right: 1px solid $invevo-light-grey;
}

.horizontalDivider {
    border-top: 1px solid $invevo-light-grey;
}

.border {
    border-left: 2px solid $invevo-mid-blue;
}
