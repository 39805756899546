@import "../../../scss/variables.scss";

.tableRow {
    background-color: #cfe2e9;
    box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.4);
    border-radius: 5px;

    &:hover {
        box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.4), inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    }
}

.roundedLeft {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.roundedRight {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.selected {
    background: #beeaff;
}

.dataItem {
    color: $invevo-text;
}

.stickRight {
    right: 0;
    z-index: 1;
}

.checkbox {
    box-shadow: inset 0px 0px 8px #00000073 !important;
    border-radius: 5px;
}
