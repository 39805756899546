@import "../../scss/variables.scss";

.menuButtons {
    outline: 1px solid $invevo-blue;
    border-radius: 0.375rem;
}

.selectedButton {
    background-color: $invevo-blue;
    color: #ffffff;
}

.buttonPadding {
    background-color: $invevo-blue;
    width: 1px;
}
