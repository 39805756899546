@import "../../../scss/variables.scss";

.tag {
  min-height: 24px;
  max-height: 24px;
  border-radius: 20px;
}

.tag-delete {
  color: rgba(74, 74, 74, 0.3);
  transition-duration: 500ms;
  &:hover {
    color: $invevo-red;
  }
}
