.checkbox {
  border: 1px solid (255, 255, 255, 0.9);
  border-radius: 10%;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5),
    inset -1px -1px 3px rgba(255, 255, 255, 0.4);
  width: 20px;
  height: 20px;
}

.disabled {
  opacity: 0.5;
}
