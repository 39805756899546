@import "../../../../../scss/variables.scss";

.container {
    min-width: 280px;
    height: 60px;
    background-color: $invevo-background-grey;
}

.target-input {
    height: 22px;
    border-radius: 7px;
    border: none;
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    color: $invevo-text;
    width: 100%;
}

