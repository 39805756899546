.widget-white {
    box-shadow: 3px 3px 12px rgba(50, 50, 50, 0.6), -3px -3px 12px rgba(255, 255, 255, 0.2);
    background: linear-gradient(#ffffff, #d3d3d3);
}

.widget-blue {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.6), -5px -5px 8px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(#1997d7, #4655cc);
}

.chart {
    background-color: transparent;

    line {
        stroke-dasharray: 0;
    }

    path {
        stroke-width: 2px;
    }
}

.chartContainer {
    position: relative;
}
.chart-white {

    path,
    line {
        stroke: #00000022;
    }

    text {
        color: #00000088;
        fill: #00000088;
    }
}

.chart-blue {

    path,
    line {
        stroke: #ffffff33;
    }

    text {
        color: #ffffff99;
        fill: #ffffff99;
    }
}

.average-line {
    line {
        stroke: #9fea84;
        stroke-width: 4px;
        stroke-dasharray: 5px, 3px;
    }
}

.average-line-legend {
    border: 2px dashed #9fea84;
}