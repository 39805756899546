.overlay {
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
}

.editPanel {
    overflow: auto;
}

.actionRow {
    top: 0;
    margin-bottom: auto;
}
