.flowsContainer {
    min-width: 300px;
    max-width: 300px;
    height: 100%;
    box-shadow: 0 15px 15px rgba(50, 50, 50, 0.2);
    overflow-y: auto;
    position: absolute;
    left: 0;
    z-index: 2;
}

.rightContainer {
    position: absolute;
    right: 0;
    z-index: 2;
}

.nodesContainer {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    box-shadow: 0 15px 15px rgba(50, 50, 50, 0.2);
    overflow-y: auto;
}

.editingContainer {
    min-width: 400px;
    max-width: 400px;
    height: 100%;
    box-shadow: 0 15px 15px rgba(50, 50, 50, 0.2);
    overflow-y: auto;
}
