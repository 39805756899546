@import '../../../scss/variables.scss';

.options {
    border-radius: 0 0 7px 7px;
    z-index: 1000;
}

.inputWithMatches {
    input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: inset 0 7px 10px -7px rgba(50, 50, 50, 0.5), inset 7px 0 10px -7px rgba(50, 50, 50, 0.5), inset -7px 0 10px -7px rgba(50, 50, 50, 0.5);

        &:focus {
            box-shadow: inset 0 7px 10px -7px rgba(50, 50, 50, 0.5), inset 7px 0 10px -7px rgba(50, 50, 50, 0.5), inset -7px 0 10px -7px rgba(50, 50, 50, 0.5);
        }
    }
}

.scrollable {
    max-height: 450px;
    overflow-y: auto;
}

.applyButton {
    border: 1px solid #1874aa;
    max-height: 35px;
    width: 100px;
    border-radius: 20px;
    background-color: #1874aa;
    color: white;
    box-shadow: inset 0 0 5px #0d3b57;
}

.disabledButton {
    cursor: not-allowed;
    opacity: 0.5;
}

.parameters {
    min-height: 36px;
    border-radius: 7px;
    border: none;
    height: auto;
    box-shadow: inset 0 0 10px rgba(50, 50, 50, 0.5);
    color: $invevo-text;

    &:focus {
        color: $invevo-dark-grey;
    }
}

.input {
    border: none;
    outline: none;
    background-color: transparent;
}

.spinnerContainer {
    right: 0.5rem;
    top: calc(50% - 0.75rem);
}