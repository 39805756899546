.notes-container {
  position: relative;
  z-index: 1;
  padding: 30px 20px 30px 30px;
  max-width: 409px;
  min-height: 1000px;
  margin-top: 15px;
  overflow: visible;
  background-image: url('./../../images/fridge_top.png'),
    url('./../../images/fridge_bottom.png'),
    url('./../../images/fridge_middle.png');

  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: left top, left bottom, left 90%;
  background-size: 409px 343px, 409px 25px, 100% 100%;

  .postit {
    .btn-link {
      color: #060;
      margin-top: -65px;
      margin-right: -10px;
    }
    p {
      overflow: hidden;
      color: #060;
      font-family: sans-serif;
      margin-top: -30px;
      max-height: 120px;
    }

    padding: 0.75em;
    margin-bottom: 0.75em;
    color: #060;
    line-height: 1;
    width: 160px;
    min-height: 160px;
    max-height: 160px;
    position: relative;
    border: 1px solid #e8e8e8;
    border-top: 30px solid #fcfc72;
    font-family: 'Reenie Beanie';
    border-bottom-right-radius: 60px 5px;
    display: inline-block;
    background: #ffff88; /* Old browsers */
    background: -moz-linear-gradient(
      -45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right bottom,
      color-stop(81%, #ffff88),
      color-stop(82%, #ffff88),
      color-stop(82%, #ffff88),
      color-stop(100%, #ffffc6)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      -45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      -45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      -45deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%
    ); /* IE10+ */
    background: linear-gradient(
      135deg,
      #ffff88 81%,
      #ffff88 82%,
      #ffff88 82%,
      #ffffc6 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffff88', endColorstr='#ffffc6',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  .postit:after {
    content: '';
    position: absolute;
    z-index: -1;
    right: -0px;
    bottom: 15px;
    width: 100px;
    height: 20px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
    -moz-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -webkit-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -o-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    -ms-transform: matrix(-1, -0.1, 0, 1, 0, 0);
    transform: matrix(-1, -0.1, 0, 1, 0, 0);
  }
}
