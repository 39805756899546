.action-button {
    box-shadow: 2px 2px 5px #0087c4, -2px -2px 5px#08a6ee; 
    border-radius: 8px 8px 8px 8px;
    
    &.disabled {
        opacity: 0.5;
    }
        
    &:hover:not(.disabled) {
        .icon {
            color: #1aa1dd; 
            background-color: #ddf1fa !important;
        }
        
        .label {
            color: #30a8df; 
            background-color: white !important;
        }
    }
    
    .icon {
        border-radius: 0 8px 8px 0;
        background-color: #1aa1dd; 
        color: #e3e4e9;
    }
    
    .label {
        border-radius: 8px 0 0 8px;
        background-color: transparent;
    }
}