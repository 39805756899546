@import '../../../scss/variables.scss';

.container {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.4), -5px -5px 8px rgba(255, 255, 255, 0.4);
    border-radius: 10px 10px;
}

.iconContainer {
    height: 150px;
    border-radius: 10px;
    background: linear-gradient(0deg, $invevo-blue-gradient, $invevo-blue);
    font-size: x-large;

    border-top: solid 2px $invevo-blue;
    border-left: solid 2px transparent;
    border-right: solid 2px transparent;

    &:hover {
        border-top: solid 2px $invevo-blue-gradient;
        border-left: solid 2px $invevo-blue-gradient;
        border-right: solid 2px $invevo-blue-gradient;
    }
}