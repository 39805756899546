.customer-label {
    min-height: 24px;
    max-height: 24px;
    border-radius: 20px;
    background-color: #47a5bd;
}

.selected {
    border: 3px solid #fff;
}
