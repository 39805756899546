@import "../../../../scss/variables.scss";

.widget {
    box-shadow: 0 0 5px 2px #00000020;
}

.label {
    color: #5d7080;
}

.filter-button {
    min-height: 36px;
    max-height: 36px;
    background-color: $invevo-background-grey;
    box-shadow: 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.selected-filter-button {
    box-shadow: inset 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.filter-count-label {
    border-radius: 50%;
}

.filters-container {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.remove-field-icon {
    color: #ccc;
}
