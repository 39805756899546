@import "../../scss/variables.scss";

.input {
    width: 10%;
    border-radius: 7px;
    border: none;
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    color: $invevo-text;
    text-align: center;

    &:focus {
        box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
        color: $invevo-dark-grey;
    }
}