@import "../../scss/variables.scss";

.container {
  min-width: 100%;
  max-width: 100%;
  overflow-y: auto;
}

.requestButton {
  max-height: 5rem;
  height: 125%;
}
