@import "../../../../../scss/variables.scss";

.card {
    background-color: $invevo-background-grey;
}

.icon {
    color: $invevo-grey;
    font-size: 20px;
}

.verticalDivider {
    border-right: 1px solid $invevo-light-grey;
}

.horizontalDivider {
    border-top: 1px solid $invevo-light-grey;
}

.smallInput {
    max-width: 100px;
}
