@import "../../../scss/variables.scss";

.separator {
    opacity: 0.3;
    height: 1px;
}

.customHandle {
    width: 10px;
    height: 10px;
    border: none;
    top: auto;
    transform: translate(0, 0);
}

.node {
    border-radius: 2px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);

    &.success {
        :global(.selected) & {
            outline: 4px solid var(--bs-success);
            box-shadow: 0 0 10px 2px var(--bs-success);
        }
    }

    &.primary {
        :global(.selected) & {
            outline: 4px solid var(--bs-primary);
            box-shadow: 0 0 10px 2px var(--bs-primary);
        }
    }

    &.warning {
        :global(.selected) & {
            outline: 4px solid var(--bs-warning);
            box-shadow: 0 0 10px 2px var(--bs-warning);
        }
    }

    &.danger {
        :global(.selected) & {
            outline: 4px solid var(--bs-danger);
            box-shadow: 0 0 10px 2px var(--bs-danger);
        }
    }

    &.secondary {
        :global(.selected) & {
            outline: 4px solid var(--bs-secondary);
            box-shadow: 0 0 10px 2px var(--bs-secondary);
        }
    }
}

.nodeTopBorder {
    min-height: 0.5rem;
    margin-top: -1px;
}

.description {
    width: 0;
    min-width: 100%;
}
