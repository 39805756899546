@import "../../../scss/variables.scss";

.label {
    background-color: #ffffffd7;
    animation: slideIn 0.2s ease-in;
    color: $invevo-text;
    box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    z-index: 9999;
}

@keyframes slideIn {
    from {
        background-color: transparent;
        color: transparent;
        box-shadow: none;
    }

    to {
        background-color: #ffffffd7;
        color: $invevo-text;
        box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    }
}
