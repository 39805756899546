@import "../../../scss/variables.scss";

.container {
    width: 600px;
}

.roles {
    height: 60vh;
    overflow-y: auto;
}

.selected-checkbox {
    background-color: #eff0f5;
    border: 1px solid transparent;
    border-radius: 3px;
    
    div {
        box-shadow: none;
    }
}

.unselected-checkbox {
    background-color: #eff0f5;
    border: 1px solid #55697A4D;
    border-radius: 3px;
    
    div {
        box-shadow: none;
    }
}

.search {
    border-radius: 20px;
    background-color: #f8f8f8;
    
    input {
        background-color: #f8f8f8;
        border: none;
        box-shadow: none;
        padding-left: 0.5rem;
        padding-top: 0;
        padding-bottom: 0;
        color: $invevo-text;
        
        &:focus {
            background-color: #f8f8f8;
            border: none;
            box-shadow: none;
            color: $invevo-text;
        }
    }
}