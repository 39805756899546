.c3-axis-y text {
    font-size: 14px;
    font-family: "PTSans"
}

.c3-axis-x text {
    font-size: 14px;
    font-family: "PTSans"
}

.c3-chart-arc path {
    stroke-width: 0;
}