.c3 {
    .averageLine {
        line {
            stroke: orange !important;
            stroke-dasharray: 5px 3px;
            stroke-width: 4px;
        }
    }
}

.widget-white {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.6), -5px -5px 8px rgba(255, 255, 255, 0.2);
    background: linear-gradient(#ffffff, #d3d3d3);
}

.widget-blue {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.6), -5px -5px 8px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(#1997d7, #4655cc);
}

.chart-white {
    background-color: white;
}

.chart-blue {
    background-image: linear-gradient(#1997d7, #4655cc);
}

.average-line-legend {
    border: 2px dashed orange;
}