@import "./variables.scss";

.mb-tiny {
    margin-bottom: 2px !important;
}

.table > :not(:first-child) {
    border-top: 0;
}

.max-dashboard-height {
    max-height: 400px;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.no-select {
    user-select: none;
}

.text-blue {
    color: $invevo-blue;
}

.text-purple {
    color: $invevo-purple;
}

.text-white {
    color: white;
}

.text-light-blue {
    color: $invevo-light-blue;
}

.text-black {
    color: black;
}

.text-grey {
    color: $invevo-text;
}

.text-red {
    color: $invevo-red;
}

.text-light-grey {
    color: $invevo-light-grey;
}

.text-orange {
    color: #ffa10b;
}

.text-dark-grey {
    color: $invevo-dark-grey;
}

.bg-dark-gradient {
    background-image: $invevo-background-gradient-dark;
}

.bg-grey {
    background-color: $invevo-light-grey;
}

.bg-blue {
    background-color: $invevo-blue;
}

.bg-light-blue {
    background-color: $invevo-light-blue;
}

.bg-light-grey {
    background-color: $invevo-light-grey;
}

.bg-white {
    background-color: white;
}

.text-green {
    color: #66ad4d;
}

.text-light-green {
    color: #a3e789;
}

.text-very-dark-blue {
    color: #55697a;
}
