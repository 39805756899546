@import "../../../scss/variables.scss";

.button {
    &.disabled {
        opacity: 0.5;
    }

    .icon {
        border-radius: 7px 0px 0px 7px;
        width: 4rem;
    }

    .label {
        border-radius: 0px 7px 7px 0px;
    }
}

.button-grey {
    border-radius: 7px;
    box-shadow: 3px 3px 8px rgba(50, 50, 50, 0.4), -3px -3px 5px rgba(255, 255, 255, 0.4);

    .icon {
        background-color: #e3e4e9;
        color: $invevo-blue;
    }

    .label {
        color: $invevo-text;
        background-color: $invevo-background-grey;
    }

    &:hover:not(.submitting):not(.disabled) {
        .label {
            color: white;
            background-color: #30a8df;
        }

        .icon {
            color: white;
            background-color: $invevo-blue;
        }
    }
}

.button-white {
    .icon {
        background-color: $invevo-blue;
        color: white;
    }

    .label {
        color: $invevo-text;
        background-color: white;
        border-top: 1px solid $invevo-blue;
        border-right: 1px solid $invevo-blue;
        border-bottom: 1px solid $invevo-blue;
    }

    &:hover:not(.submitting):not(.disabled) {
        .label {
            color: white;
            background-color: $invevo-blue;
        }
    }
}

.button-blue {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.4), -5px -5px 8px rgba(255, 255, 255, 0.4);
    border-radius: 7px;

    .icon {
        color: white;
        background-color: #149edc;
    }

    .label {
        color: white;
        background-color: $invevo-blue;
    }

    &:hover:not(.submitting):not(.disabled) {
        .icon {
            color: $invevo-blue;
            background-color: #ddf1fa;
        }

        .label {
            color: $invevo-blue;
            background-color: white;
        }
    }
}

.disappear {
    display: none;
    visibility: hidden;
    height: 0;
}
