@import "../../../scss/variables.scss";

.widgets {
    background-color: #0087c3;
    border-radius: 7px;
    box-shadow: inset 0 0 10px #00000010;
}

.filter-button {
    background-color: $invevo-background-grey;
    box-shadow: 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.selected-filter-button,
.filter-button:active {
    box-shadow: inset 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.filter-count-label {
    border-radius: 50%;
}

.filters-container {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.widget {
    box-shadow: 0 0 5px 2px #00000020;
}
