@import "../../../scss/variables.scss";

.tableHead {
    position: sticky;
    top: 0.25rem;
    z-index: 20;
    box-shadow: 0.5rem 0px 1px $invevo-blue, -0.5rem 0px 1px $invevo-blue, 0px -0.25rem 1px $invevo-blue;
}

.tableHead tr:first-child + tr th {
    border-top: 1px solid $invevo-background-grey;
    padding-top: 0.5rem;
}

.headerRow {
    position: relative;
    height: 3rem;
    box-shadow: 0px 0.25rem 0px $invevo-blue;
}

.aggregationRow {
    box-shadow: 0px 0.5rem 0px $invevo-blue;
}

.aggregationCell {
    padding-left: 0.5rem;
    height: 1rem;
}

.table {
    border-collapse: separate;
    border-spacing: 0px 0.25rem;
}

.checkbox {
    box-shadow: inset 0px 0px 8px #00000073 !important;
    border-radius: 5px;
}
