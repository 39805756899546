$mia-blue: #0096d9;
$mia-blue-highlight: #ddf1fa;
$mia-light-blue-text: #48ace2;

$mia-green: #00bd9d;
$mia-purple: #904997;
$mia-bright-green: #85c400;

$mia-dark-blue: #2c5797;
$mia-darker-blue: #2d3461;
$mia-dark-grey: #101f2c;
$mia-warn-grey: #4a4a4a;
$mia-grey: #c1c2cc;
$mia-light-grey: #d5dae0;
$mia-background-grey: #eff0f5;
$mia-greyer: #9496a7;
$mia-text: #556972;

$white: #fff;
$black: #000;

$pad: 10px;

$mia-alert: #ffa10b;
$mia-action: #0096d3;
$mia-action-dark: #2682d5;
//action menu and panel bgg colours # because of gradiant
// $mia-action-0: #65c7f4; // task
// $mia-action-1: #3eb6eb; // email
// $mia-action-2: #26aae4; // sms
// $mia-action-3: #1da0db; // phone
// $mia-action-4: #2682d5; // post
// $mia-action-5: #3867d2; // credit
// $mia-action-6: #4d47d0; // portal
