@import "../../../../scss/variables.scss";

.container {
    box-shadow: 0 5px 6px 5px rgba(180, 180, 180, 0.603);
    z-index: 1;
}

.gridBackground {
    background-image: $invevo-background-gradient-dark;
}

.sidebar {
    width: 30vw;
    min-width: 30%;
}
