@import "../../../scss/variables.scss";

.menu-item {
    height: 6vh;
    border-left: 3px solid transparent;
}

.label {
    background-color: #ffffffdd;
    animation: slideIn 0.2s ease-in;
    color: $invevo-text;
    box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    z-index: 9999;
    transform: translate(50px, -6vh);
}

.active {
    background-color: #00000022;
    border-radius: 7px;
    border: 1px solid #00000044;
}
