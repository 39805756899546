@import "../../../scss/variables.scss";

.menu-item {
    height: 6vh;
    border-left: 3px solid transparent;
}

.active {
    background-color: #00000011;
    border-left: 3px solid #0096D9;
    box-shadow: inset -1px 10px 5px -3px #00000022;
}

.active-with-no-sub-menu {
    box-shadow: inset -1px 10px 5px -3px #00000011, inset -1px -10px 5px -3px #00000011;
}

.label {
    background-color: #ffffffdd;
    animation: slideIn 0.2s ease-in;
    color: $invevo-text;
    box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    z-index: 9999;
}

.sub-menu {
    box-shadow: inset -1px 10px 5px -3px #00000033, inset -1px -10px 5px -3px #00000033;
}

@keyframes slideIn {
    from { background-color: transparent; color: transparent; box-shadow: none }
    to { background-color: #ffffffdd; color: $invevo-text; box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011; }
}