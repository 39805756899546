@import '../../../../scss/variables.scss';

.dropdown {
    border-radius: 7px;
    background-color: $invevo-blue;
    color: white;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .3), -5px -5px 8px rgba(255, 255, 255, .3);
    max-width: 100px;
    min-width: 100px;
    height: 35px;
}

.dropdown-content {
    border-radius: 7px 7px 7px 7px;
    background-color: $invevo-blue;
    color: white;
    width: 220px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .3), -5px 0 8px rgba(255, 255, 255, .3);
    margin-left: -10px;
}

.option {
    &:hover {
        background-color: $invevo-dark-blue;
    }
}