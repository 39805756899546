/*
This file is used to contain all component imports.
Files inside this folder should contain all styles relating
to a reusable component.
*/

@import './_contactPanel.scss';
@import './_documentsPanel.scss';
@import './_taskList.scss';
@import './_task.scss';
@import './_userTaskList.scss';
@import './_admin.scss';
@import './_search.scss';
@import './_transactionsPanel.scss';
@import './_accountSummaryPanel.scss';
@import './_virtualParent.scss';
@import './_pinNotes.scss';
@import './_repaymentPlan.scss';
@import './_sms.scss';

//
@import './_oddsAndEnds.scss';

.mia-success {
  color: $mia-green;
}
/* logos */

.logo {
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-image: url(./../../images/logos/Icon.png);
}

.mia-logo {
  width: 40px;
  height: 38px;
  background-size: 40px 38px;
  background-image: url(./../../images/logos/mia-logo.png);
  &.mia-logo-xs {
    width: 20px;
    height: 19px;
    background-size: 20px 19px;
  }
}

// must be a nicer way to do this...
.oval {
  &:before {
    content: '';
    display: block;
    padding-top: 100%; /* initial ratio of 1:1*/
  }

  width: 100%;
  line-height: 100%;
  display: inline-block;
  background-color: #43bcf2;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: $white;
    font-size: 25px;
  }
}

.speech-bubble {
  background: $mia-background-grey;
  -webkit-border-radius: 17px 17px 17px 0;
  border-radius: 17px 17px 17px 0;
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0 auto 40px;
  max-width: 100px;
  padding: 7px;
  position: relative;

  p {
    margin: 10px 0;
    text-align: center;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  > div {
    margin: 5px auto;
  }

  &::after {
    border-right: 20px solid transparent;
    border-top: 10px solid $mia-background-grey;
    bottom: -10px;
    content: '';
    position: absolute;
    left: 0;
  }

  &.speech-bubble-xs {
    padding: 2px;
    font-size: 0.3rem;
    line-height: 0.4;
    width: 30px;
    -webkit-border-radius: 8px 8px 8px 0;
    border-radius: 8px 8px 8px 0;
    .mia-logo {
      height: 20px;
      width: 20px;
      background-size: 20px;
    }
    > div {
      margin: 2px;
      display: inline-block;
    }

    &::after {
      border-right-width: 4px;
      border-top-width: 2px;
      bottom: -2px;
    }
  }
}

.chat-speech-bubble {
  background-color: $white;
  -webkit-border-radius: 17px;
  border-radius: 17px;
  padding: 15px;
  position: relative;

  &::after {
    border-right: 10px solid transparent;
    border-bottom: 15px solid #fff;
    bottom: 20px;
    content: '';
    position: absolute;
    right: -10px;
  }
}

/* stucture and grid */
.panel {
  margin-bottom: 0px;
}

.result-default,
.result-selected {
  padding: 5px;
  background-color: $white;
}

.nav-back {
  padding: 5px 10px;
}

.drawstring {
  background-color: $mia-light-grey;
  color: $mia-grey;
}

.drawstring.collapsed {
  background-color: unset;
}

#transaction-panel {
  .k-animation-container {
    overflow: visible;
  }
  .transaction-actions {
    .nav-pills {
      margin: 0 50px;
      // float: right;
      li {
        padding: 15px 15px 10px;

        &.active {
          background-color: $mia-background-grey;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }
      }
    }
    .tab-content {
      padding: 15px 27px;
      background-color: $mia-background-grey;
      width: 100%;

      h3 {
        padding-top: 0;
      }
    }
  }
}

#email-pane {
  input {
    width: 100%;
  }

  .success {
    padding-top: 20vh;
    text-align: center;
  }

  .box {
    margin: 5px;
    > div {
      border: 1px solid $white;
      padding: 10px 15px;
      display: inline-block;
      margin: 5px 5px 5px 0;

      span {
        vertical-align: sub;
        font-size: 20px;
        padding-right: 3px;
      }
    }
  }

  .card {
    color: $mia-dark-blue;
  }

  .preview {
    overflow-x: scroll;
    border: solid black 1px;
    width: 100%;
  }
}

#post-pane {
  .btn:disabled {
    cursor: not-allowed;
  }

  .success {
    padding-top: 20vh;
    text-align: center;
  }
}

/* Main Nav */
#main-nav {
  flex-basis: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: $mia-background-grey;
  font-size: 21px;
  line-height: 21px;
  min-height: 100vh;
  
  .primary-nav {
    height: 80vh;
    overflow-y: auto;
  }

  .logo {
    margin: 20px auto;
  }

  a {
    color: $mia-grey;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
    color: $mia-grey;

    li {
      padding: 15px 10px;
      border-left: transparent solid 3px;

      &.selected {
        border-left: $mia-blue solid 3px;
        color: $mia-blue;
      }
    }
  }

  .user-nav {
    width: 50px;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}
.email-display-iframe {
  border: 1px solid $mia-greyer;
  width: 100%;
  min-height: 1px;
  background: white;
  overflow-y: hidden;
}

#left-pane,
#right-pane {
  flex-shrink: 1;
  flex-grow: 1;
}

.inline-label {
  display: inline;
}

.drawstring {
  cursor: pointer;
  font-weight: bold;
  color: $mia-dark-blue;
  margin: 8px 0 8px 0;
  background-color: $mia-background-grey;
}

.value-range {
  margin-bottom: 8px;
  input {
    display: block;
  }
}

.date-range {
  margin-bottom: 8px;
  input {
    display: block;
    width: 100%;
    padding: 5px 30px 5px 6px;
    color: $mia-text;
    background-image: url(./../../images/input-icons/calendar-grey.png);
    background-color: $white;
    background-repeat: no-repeat;
    font-size: 14px;
    background-position: right 4px top 4px;
    border: 1px solid $mia-light-grey;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}

.multi-select {
  select {
    font-size: 12px;
    width: 100%;
  }
}

.multi-select-controls {
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  text-align: center;
  margin-top: 8px;
  button {
    color: $mia-text;
  }
  .multi .btn {
    padding-left: 3px;
    padding-right: 3px;
  }
}

.button-invevo {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .4), -5px -5px 8px rgba(255, 255, 255, .4);
    border-radius: 7px;
    background-color: $mia-blue;
    border: none;
    align-items:flex-start;
    &.disabled{
      opacity: 0.5;
    }
}
