@import "../../../scss/variables.scss";

.background {
    background-image: $invevo-background-gradient-dark;
}

.container {
    min-width: 380px;
    max-width: 380px;
    box-shadow: 0 15px 15px rgba(50, 50, 50, 0.2);
    overflow-y: auto;
}
