#action-menu {
  flex-basis: 50px;
  height: 100vh;
  flex-shrink: 0;
  flex-grow: 0;
  background-image: linear-gradient(#bfebff, #159cd7, #6226cd);

  .adjust-view {
    height: 85px;
    max-height: 85px;
    padding: 27px 0;
    font-size: 16px;
    line-height: 16px;
    color: $white;
    background-color: #dedfe8;

    svg:first-of-type,
    svg:last-of-type {
      cursor: pointer;
      font-size: 17px;
      &.disabled {
        cursor: default;
      }
    }

    span.pipe {
      font-size: 20px;
      cursor: col-resize;
      &.disabled {
        cursor: default;
      }
    }

    svg:first-of-type {
      padding-left: 6px;
    }
    svg:last-of-type {
      padding-right: 6px;
    }
  }

  ul {
    padding-left: 0;
    list-style-type: none;
    cursor: pointer;

    .disabled {
      cursor: not-allowed;
      color: $mia-grey;
      filter: grayscale(0.5);
    }

    .alertActive {
      background-color: red;
    }

    li {
      display: inline-block;
      position: relative;
      padding: 3vh 0;
      text-align: center;
      width: 100%;
      font-size: 18px;
      line-height: 18px;
      color: $white;
      border: 1px solid transparent;

      &.mia-action,
      &:hover {
        border-top-color: $mia-action-dark;
        border-bottom-color: $mia-action-dark;
        border-right-color: $mia-action-dark;
      }
    }
  }
}

// light on dark - inverse colours
[class*='mia-action'] {
  .box > div {
    border-color: white;
  }
  input {
    color: $mia-text;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }

  .btn-link {
    color: $white;
  }

  .btn-default {
    color: $white;
    border-color: $white;
    background-color: unset;
    &:hover:not([disabled]),
    &:visited {
      border-color: $mia-blue;
      background-color: $mia-dark-blue;
    }
    &:active {
      color: $white;
      box-shadow: none;
      background-color: $mia-dark-blue;
    }
    &:focus {
      color: $white;
      outline-color: $mia-blue;
      background-color: $mia-dark-blue;
    }
  }
}

.mia-action {
  background-color: $mia-action;
}

#action-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .fixed-toolbar {
    flex-shrink: 0;
  }
  .action-body {
    flex-shrink: 1;

    &.inv {
      color: $white; // action pane have color
    }

    &.k-scrollable {
      overflow: auto;
    }

    &.left-scroll {
      direction: rtl;
      > div {
        direction: ltr;
      }
    }
  }
  .action-body {
    background-color: $mia-background-grey;
    height: 100vh;
    &.active-fixed-toolbar {
      height: calc(100vh - 51px);
    }
    &.mia-action {
      background-color: $mia-action;
    }
  }
}
