@import "../../../../scss/variables.scss";

.container {
    border-radius: 7px;
    border: 1px solid $invevo-mid-blue;
    background: $invevo-mid-blue;
}

.removeRoleButton {
    background-color: white;
    border: 1px solid #1c95d734 !important;
    box-shadow: none !important;
    color: $invevo-blue;
}
