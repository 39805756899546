@import "../../scss/variables.scss";

.swal2-container {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: center !important;
    padding: 0px !important;
}

.swal2-popup {
    position: fixed !important;
    top: 10vh !important;
    border-radius: var(--bs-border-radius) !important;
}

.swal2-styled {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.4), -5px -5px 8px rgba(255, 255, 255, 0.4) !important;
    border-radius: 7px !important;
    font-size: 1.25rem !important;
    font-weight: 400 !important;

    padding: 0.25rem 1rem 0.25rem 1rem !important;

    &:hover {
        background-color: white !important;
    }
}

.swal2-confirm:hover {
    color: $invevo-blue !important;
}

.swal2-cancel:hover {
    color: $invevo-red !important;
}

.swal2-confirm::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: "\f00c";
    margin-right: 0.5em;
}

.swal2-cancel::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: "\f00d";
    margin-right: 0.5em;
}
