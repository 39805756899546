@import "../../scss/app.scss";

.widget-white {
    background: linear-gradient(#ffffff, #d3d3d3);
    box-shadow: 3px 3px 12px rgba(50, 50, 50, 0.6), -3px -3px 12px rgba(255, 255, 255, 0.2);

    span {
        font-size: 10px;
    }
}

.widget-blue {
    background-image: linear-gradient(#1997d7, #4655cc);
    box-shadow: 3px 3px 12px rgba(50, 50, 50, 0.6), -3px -3px 12px rgba(255, 255, 255, 0.2);

    span {
        font-size: 10px;
    }
}

.text-white-info {
    color: #0096d9;
}

.text-blue-info {
    color: white;
}

.text-white-good {
    color: #0096d9;
}

.text-blue-good {
    color: #0fc9a4;
}

.text-white-bad {
    color: #0096d9;
}

.text-blue-bad {
    color: #ee4057;
}

.wrapper-white {
    border: 1px solid #1997d7;
    border-radius: 7px;
    background-color: #32a2db;
}

.wrapper-blue {
    border: 1px solid white;
    border-radius: 7px;
    background-color: #e9effa;
}

.hide {
    visibility: hidden;
}

.zero-min-width {
    min-width: 0;
}
