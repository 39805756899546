.background {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
}

.overlay {
    display: inline-block;
    margin: 10vh;
    box-shadow: 0 0 20px #000000;
    height: 80vh;
    overflow-y: auto;
}

.iconButton {
    width: 44px;
    height: 44px;
    box-shadow: 0.15rem 0.15rem 0.3rem 0.15rem #0000001d, -0.15rem -0.15rem 0.3rem 0.15rem #ffffff15;
    border-radius: 0.75rem;
}