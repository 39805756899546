#vp-button {
  max-width: 95%;
  display: inline-block;
  border-radius: 0.25rem;
  border: 1px solid #738da8;
  padding: 0 0.25rem;
  margin: 0.25rem 1rem;
  position: absolute;
  background-color: $mia-background-grey;
  z-index: 1000;
  max-height: 95vh;
  overflow-y: auto;

  &.show {
    -webkit-box-shadow: 0px 0px 22px 5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 22px 5px rgba(0, 0, 0, 0.5);

    .hierarchy {
      float: right;
      margin-top: 0.5rem;
    }

    .highlight,
    .hierarchy {
      color: $mia-blue;
    }
  }

  .custom-grid.k-grid {
    background-color: $mia-background-grey;
    border-color: $mia-background-grey;

    .k-grid-header th {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: $mia-text;
    }
    .k-grid-footer {
      padding-right: 0 !important;
      td {
        padding: 8px;
      }
    }
  }

  .k-master-row {
    background-color: $mia-background-grey;
    td {
      background-color: $mia-background-grey;
      min-height: 34px;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;

      &:first-of-type {
        border-left: 2px solid transparent;
      }
      &:last-of-type {
        border-right: 2px solid transparent;
      }

      &.k-command-cell {
        padding: 0.25rem;
      }
    }
  }

  .type-this td {
    border-top-color: $mia-blue;
    border-bottom-color: $mia-blue;

    &:first-of-type {
      border-left-color: $mia-blue;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-right-color: $mia-blue;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .k-grid-norecords {
    display: none;
  }

  .k-button.k-grid-hierarchy {
    padding-top: 8px;
    padding-bottom: 8px;
    color: $mia-blue;
  }

  .fixed-relationship {
    width: 100px;
  }
  .relationship {
    width: 330px;
  }
}
