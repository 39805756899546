.left-side {
    border-right: 1px solid #BCBCBC;

    i {
        color: #BCBCBC;
    }
}

.header {
    border-bottom: 1px solid #BCBCBC;
}

.add-rule-button {
    border-right: 1px solid #BCBCBC;
}

.header-icon {
    color: #BCBCBC;
}