@import "../../scss/variables.scss";

.filter-button {
    background-color: $invevo-background-grey;
    box-shadow: 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.selected-filter-button,
.filter-button:active {
    box-shadow: inset 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.filter-count-label {
    border-radius: 50%;
}

.filters-container {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.widget-white {
    box-shadow: 3px 3px 12px rgba(50, 50, 50, 0.6), -3px -3px 12px rgba(255, 255, 255, 0.2);
    background: linear-gradient(#ffffff, #d3d3d3);
}

.widget-blue {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.6), -5px -5px 8px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(#1997d7, #4655cc);
}

.chart {
    background-color: transparent;

    line {
        stroke-dasharray: 0;
    }

    path {
        stroke-width: 2px;
    }
}
