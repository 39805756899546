@import "../../../scss/variables.scss";

.boundHeight {
    min-height: 2rem;
    max-height: 4rem;
}

.menuItem {
    border-left: 3px solid transparent;
    padding-right: 3px;
}

.active {
    background-color: #00000033;
    border-left: 3px solid #fff;
    box-shadow: inset -1px 10px 5px -3px #00000033, inset -1px -10px 5px -3px #00000033;
}
