@import "../../scss/variables.scss";

.role-selection {
    max-width: 300px;
    width: 300px;
}

.options {
    border-radius: 0 0 7px 7px;
    background-color: $invevo-background-grey;
    z-index: 1000;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .2),
    -10px 0px 7px -5px rgba(255, 255, 255, 1);
    max-height: 0;
    transition: max-height 0.4s ease-out;
    overflow: auto;

    &.open {
        max-height: 500px;
    }
}

.role-selection-button {
    color: $invevo-text;
    border-radius: 7px;
    background-color: $invevo-background-grey;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .2),
    -5px -5px 7px rgba(255, 255, 255, 1);
    transition-delay: 0.4s;

    &.open {
        border-radius: 7px 7px 0 0;
        transition-delay: 0s;
    }
}