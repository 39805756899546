@import "../../../scss/variables.scss";

.customerCard {
    border-radius: 3px;
    border: 2px solid $invevo-blue;

    &.selected {
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        background-color: $invevo-mid-blue;
    }
}

.separator {
    opacity: 0.3;
    height: 1px;
}
