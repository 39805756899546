.filterCount {
    left: calc(100% - 18px);
    top: -8px;
    height: 26px;
    width: 26px;
    line-height: 26px;
}

.advancedFiltersContainer {
    border-top-right-radius: 0 !important;
}
