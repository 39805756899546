@import "../../../../scss/variables.scss";

.container {
    min-width: 380px;
    max-width: 380px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
}

.separator {
    opacity: 0.3;
    height: 1px;
}

.iconButton {
    border-radius: 6px;
    background: linear-gradient(145deg, #d7d8dd, #ffffff);
    box-shadow: 6px 6px 20px #d2d3d8, -6px -6px 20px #ffffff;
}

.iconButton {
    border-radius: 6px;
    background: linear-gradient(145deg, #d7d8dd, #ffffff);
    box-shadow: 3px 3px 5px #d2d3d8, -3px -3px 5px #ffffff !important;
}
