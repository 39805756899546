@import '../../scss/variables.scss';

.noSort {
    color: $invevo-grey;
}

.dragHandle {
    opacity: 0.5;
    cursor: grab;
}

.borderRight {
    border-right: 1px solid white;
}

.borderLeft {
    border-left: 1px solid white;
}