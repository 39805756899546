.bg-green {
    background-color: #E6FAF6;
}

.bg-purple {
    background-color: #EEE9FA;
}

.bg-blue {
    background-color: #E9F2FB;
}

.bg-yellow {
    background-color: #FFFF99;
}

.bg-yellow-note {
    background-color: rgb(236, 236, 143);
}

.bg-pink {
    background-color: #fcd4dd;
}