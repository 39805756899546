@import "../../../scss/variables.scss";

.widget {
    box-shadow: 0 0 5px 2px #00000020;
}

.label {
    color: #5d7080;
}

.remove-field-icon {
    color: #ccc;
}

.filter-button {
    background-color: $invevo-background-grey;
    box-shadow: 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.selected-filter-button {
    box-shadow: inset 3px 3px 4px rgba(50, 50, 50, 0.4), -3px -3px 4px rgba(255, 255, 255, 0.4);
}

.filter-count-label {
    border-radius: 50%;
}

.filters-container {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.autocomplete-input {
    input {
        border-radius: 7px;
        border: 1px solid $invevo-grey;
        color: $invevo-text;
        background-color: #f8f8f8;
        padding: 0.25rem;
        box-shadow: none;

        &:focus {
            border: 1px solid $invevo-grey;
            box-shadow: none;
            color: $invevo-dark-grey;
            background-color: #f8f8f8;
            padding: 0.25rem;
        }
    }
}

.autocomplete-input-with-matches {
    input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: inset 0 7px 10px -7px rgba(50, 50, 50, 0.5), inset 7px 0 10px -7px rgba(50, 50, 50, 0.5), inset -7px 0 10px -7px rgba(50, 50, 50, 0.5);

        &:focus {
            box-shadow: inset 0 7px 10px -7px rgba(50, 50, 50, 0.5), inset 7px 0 10px -7px rgba(50, 50, 50, 0.5), inset -7px 0 10px -7px rgba(50, 50, 50, 0.5);
        }
    }
}
