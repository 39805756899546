.container {
    background-color: #0096d3 !important;
    color: white;
    tbody, td, tr {
        background-color: #0096d3 !important;
        color: white !important;
    }
}

.btn-custom {
    color: #fff !important;
    border-color: #fff;
    background-color: unset;

    &:hover {
        color: #fff;
        border-color: #0096d9;
        background-color: #2c5797;
    }
}