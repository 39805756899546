@import "../../scss/variables.scss";

.input {
  border-radius: 7px;
  border: none;
  box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
  color: $invevo-text;

  &:focus {
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    color: $invevo-dark-grey;
  }
}

.onTop {
  z-index: 99999;
}