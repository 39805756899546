.icon {
  color: #bcbcbc;
  width: 25px;
}

.config-item {
  width: 30%;

  .field-name {
    box-shadow: inset 0 0 5px #00000046;
    border-radius: 7px;
  }
}
