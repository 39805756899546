@import "../../../scss/variables.scss";

.main {
    grid-template-areas:
        "table add_field"
        "add_entry add_entry";
    grid-template-rows: min-content min-content;
    grid-template-columns: auto;
}

.tableWrapper {
    max-height: 70vh;
    min-height: 30vh;

    thead tr th {
        position: sticky;
        top: 0;
        z-index: 999;
    }

    th {
        height: 3.5em;
    }

    table {
        table-layout: fixed;
    }
}

.label {
    width: 300px;
}

.addField {
    grid-area: add_field;
}

.addEntry {
    grid-area: add_entry;
}

.head {
    position: sticky;
    top: 0;
    color: white;
    z-index: 2;
    box-shadow: 1rem -1px 1px $invevo-light-grey, -1rem -1px 1px $invevo-light-grey;
}
