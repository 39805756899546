.widget {
    border-radius: 7px;
    box-shadow: 0 0 7px 3px #00000020;
}

.disabled {
    opacity: 0.5;
}

.size {
    width: 80px;
}