#customer-details {
  background-color: $mia-background-grey;
  height: 100vh;

  &.stacked-layout {
    .header {
      padding: 3px 0 0;
      h1 {
        line-height: 35px;
        font-size: 20px;
      }
    }
    .k-tabstrip.top-nav {
      min-height: calc(100vh - 85px);
      > .k-tabstrip-items {
        top: 50px;

        li {
          height: 35px;
        }
        .k-link {
          padding: 8px 16px 6px;
        }
        .tab-title {
          position: relative;
          margin-top: 0;
          height: 18px;

          svg {
            position: absolute;
            left: 0;
            top: 0;
          }
          > span {
            margin-left: 24px;
            line-height: 18px;
          }

          .notify-count {
            top: -7px;
            right: -10px;
          }
        }
      }
    }
  }

  .block-ui-container {
    cursor: not-allowed;
  }

  .header {
    background-color: $mia-background-grey;
    width: 100%;
    height: 85px;
    padding: 10px 0 0;

    h1 {
      line-height: 40px;
      font-size: 23px;
      margin: 0 0 0 31px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .show h1 {
      white-space: unset;
    }

    div.box {
      background-color: $mia-action;
      color: $white;
      text-align: center;
      font-size: 14px;
      padding: 11px;
      margin: 0px 8px;
    }
  }

  .k-tabstrip.top-nav {
    min-height: calc(100vh - 85px);

    > .k-tabstrip-items {
      position: absolute;
      top: 0;
      right: 78px;
      background-color: $mia-background-grey;

      border: 0;
      padding: 0;

      .tab-title {
        margin-top: 8px;
        position: relative;
        width: 100%;

        .notify-count {
          height: 15px;
          min-width: 15px;
          font-size: 11px;
          line-height: 15px;
          padding: 0 2px;

          position: absolute;
          top: -10px;
          right: 15px;
          border-radius: 50%;
          background-color: $mia-alert;
          color: $white;
        }

        span {
          font-size: 12px;
          text-transform: uppercase;
        }
      }
      span {
        text-align: center;
        display: inline-block;
        font-size: 18px;
        line-height: 18px;
        color: $mia-text;
      }
      .k-state-active > span {
        color: $mia-blue;
      }

      li {
        min-width: 104px;
        height: 85px;
        border: 0;
        border-top: 3px solid transparent;
      }

      li.k-item.k-state-active {
        border-radius: 0;
        border: 0;
        border-top: 3px solid $mia-blue;
      }
    }
  }

  .k-content {
    max-height: calc(100vh - 85px); // height of header and margins
  }
  
  .new-customer-summary-dashboard-tab {
    height: 100%;
    div[role="tabpanel"] { height: 100% }
    
    .k-animation-container {
      overflow: auto;
      
      height: 1000px;
      width: 100%;
      .k-child-animation-container {
        height: 100%;
        div[role="tabpanel"] { height: 100% }
      }
    }
  }
}