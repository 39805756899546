#user-task-list {
  #task-date .btn-group,
  .dropdown-toggle,
  .dropdown-menu {
    width: 100%;
  }

  .section-1 {
    padding-left: 15px;
    width: calc(50% - 15px);
  }

  .section-3 {
    padding-right: 15px;
    width: calc(50% - 15px);
  }

  .section-2 {
    width: 30px;
    text-align: center;
    line-height: 38px;
  }

  min-height: 75vh;

  .k-grid {
    background-color: $mia-background-grey;
  }
  .k-grid-table {
    border-spacing: 0 15px;
  }

  tr:not(.k-state-selected) .k-hierarchy-cell,
  .result-default {
    background-color: $mia-action;
    border: 1px solid transparent;
    color: $mia-dark-grey;
    padding-bottom: 15px;
    &:hover {
      filter: grayscale(0.1);
    }
  }

  .k-state-selected .k-hierarchy-cell,
  .result-selected {
    border: 0;
    color: $mia-dark-grey;
    background-color: $mia-blue-highlight;
    border-bottom: 3px solid $mia-blue;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
