@import "../../../../../scss/variables.scss";

.container {
    min-height: 180px;   
    background-color: $invevo-background-grey;
}

.container-header {
    height: 60px;
    background-color: $invevo-light-grey;
}

.summary-item {
    min-height: 80px;
    width: 80px;
    background-color: $invevo-light-grey;
    border-radius: 10px;

    .summary-item-label {
        font-size: 12px;
    }
}

.small-text {
    font-size: 12px;
    color: $invevo-dark-grey
}

.summary-target {
    min-height: 80px;
    width: 80px;
    border-radius: 10%;

    .text {
        font-size: 10px;
    }

    &.green {
        border: 2px solid #28D04A;
        background: rgb(#28D04A, .1); 
    }
    &.blue {
        border: 2px solid $invevo-blue;
        background: rgb($invevo-blue, .1); 
    }
    &.amber {
        border: 2px solid orange;
        background: rgb(orange, .1); 
    }
    &.red {
        border: 2px solid red;
        background: rgb(red, .1); 
    }
}


.target-input {
    height: 30px;
    border-radius: 7px;
    border: none;
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    color: $invevo-text;
    width: 100%;
}
