@import "../../../../scss/variables.scss";

.node {
    border-radius: 2px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
}

.nodeTopBorder {
    min-height: 0.5rem;
}

.icon {
    width: 1.5rem;
}
