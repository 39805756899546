@import "../../scss/variables.scss";

.dashboard {
    background-image: linear-gradient(#455362, #272e35);
}

.filterCount {
    background-color: $invevo-blue;
    left: calc(100% - 18px);
    top: -8px;
    height: 26px;
    width: 26px;
    line-height: 26px;
}

.header {
    min-height: 70px;
    max-height: 70px;
    height: 7vh;
}

.virtualParentContainer {
    height: 42px;
}
