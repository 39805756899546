@import "../../../scss/variables.scss";

.scrolling-form {
    overflow-x: hidden;
    overflow-y: auto;
}

.container {
    width: 700px;
}

.icon-inactive {
    color: $invevo-background-grey;
}

.shadow {
    box-shadow: 0 0 12px 2px #dedede;
}

.rounded {
    border-radius: 20px;
}