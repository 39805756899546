@import "../../../../scss/variables.scss";

.infoColumn {
    min-width: 350px;
}

.coloredBox {
    height: 20px;
    background-image: linear-gradient(to right, #56dd25, #409621);
    border-radius: 2px;
}

.disabledText {
    color: rgb(196, 193, 193);
}
