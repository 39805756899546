@import "../../../scss/variables.scss";

.expander {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.selected-border {
    border: 4px solid $invevo-blue;
}

.expander-line {
    border-right: 2px solid $invevo-blue;
    width: 15px;
}

.horizontal-line {
    border-top: 2px solid $invevo-blue;
    height: 1px;
    width: 20px;
}

.vertical-line {
    border-right: 2px solid $invevo-blue;
    width: 15px;
}

.left-arrow-button {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.right-arrow-button {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
