@import '../../../../scss/variables.scss';

.tableRow {
    border-bottom: 1px solid $invevo-blue;
}

.dataItem {
    min-width: 200px;
}

.selected {
    background: #beeaff;
    max-width: 30px;

    &.stickLeft {
        background: linear-gradient(to right, $invevo-blue, $invevo-blue 1px, #beeaff 1px, #beeaff 100%);
        border-bottom: 1px solid $invevo-blue;
    }

    &.stickRight {
        background: linear-gradient(to left, $invevo-blue, $invevo-blue 1px, #beeaff 1px, #beeaff 100%);
        border-bottom: 1px solid $invevo-blue;
    }

    :hover>& {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    }
}

.notSelected {
    max-width: 30px;
    background-color: #ebecf1;

    &.stickLeft {
        background: linear-gradient(to right, $invevo-blue, $invevo-blue 1px, #ebecf1 1px, #ebecf1 100%);
        border-bottom: 1px solid $invevo-blue;
    }

    &.stickRight {
        background: linear-gradient(to left, $invevo-blue, $invevo-blue 1px, #ebecf1 1px, #ebecf1 100%);
        border-bottom: 1px solid $invevo-blue;
    }

    :hover>& {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    }
}

.background {
    background-color: #ebecf1;
}

.rowActionButton {
    background-color: #FFFFFFBF;
    border: 1px solid #1C95D734 !important;
    box-shadow: none !important;
}

.head {
    position: sticky;
    top: 0;
    color: white;
    background: transparent linear-gradient(180deg, #1998D7 0, #EDEEFA 80vh);
    border-bottom: 1px solid #76bfe6;
    z-index: 2;
}

.headSide {
    background: transparent linear-gradient(180deg, #1998D7 0, #EDEEFA 80vh);
    z-index: 3;
}

.stickLeft {
    position: sticky;
    left: 0;
    z-index: 1;
}

.stickRight {
    position: sticky;
    right: 0;
    z-index: 1;
}

.notAllowed {
    cursor: not-allowed;
}

.customCheckboxes {
    box-shadow: inset 0px 0px 8px #00000073 !important;
}

.commentsOverlay {
    min-width: 650px;
}

.historyOverlay {
    max-height: 90%;
    min-width: 650px;
    max-width: 700px;
}

.noSort {
    color: $invevo-grey;
}