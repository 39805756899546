@import "../../scss/variables.scss";

.input {
    border: 1px solid transparent;
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    color: $invevo-text;

    &:focus {
        box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
        color: $invevo-dark-grey;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $invevo-light-grey;
    }
}

.roundedLeft {
    border-radius: 7px 0 0 7px;
}

.roundedRight {
    border-radius: 0 7px 7px 0;
}

.rounded {
    border-radius: 7px;
}

.invalid {
    border: 1px solid $invevo-red;
}
.textbox {
    max-height: 40dvh;
}
