.options {
    border-radius: 0 0 7px 7px;
    z-index: 1000;
    box-shadow: 0 0 10px #aaa;
    clip-path: inset(0px -10px -10px -10px);
}

.icon {
    width: 20px;
    min-width: 20px;
}

.header {
    border-radius: 7px;
    box-shadow: 0 0 10px #aaa;
}

.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: inset(-10px -10px 0px -10px);
}
