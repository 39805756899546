.node {
    border-radius: 2px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
}

.customHandle {
    background: rgba(255, 255, 255, 0);
    position: absolute;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
}

.zeroSizeHandle {
    width: 0px !important;
    min-width: 0px !important;
    height: 0px !important;
    min-height: 0px !important;
    bottom: 50%;
    top: 50%;
    left: 50%;
    right: 50%;
}
