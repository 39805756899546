@import "../../../scss/variables.scss";

.chat {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 500px;
    height: 70px;
    background-color: $invevo-blue;
}

.mex {
    width: max-content;
    border-radius: 10px;
}

.currentUser {
    background-color: $invevo-purple;
}

.user {
    background-color: $invevo-blue;
}


.active {
    height: 600px;
}

.messages {
    max-height: 600px;
}

.title {
    height: 3em;
}

.textbox {
    height: 3em;
    textarea {
        margin: 0 !important;
    }
}