@import "../../scss/variables.scss";

.options {
    border-radius: 0 0 7px 7px;
    z-index: 1000;
}

.input-with-matches {
    input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.scrollable {
    max-height: 250px;
    overflow-y: auto;
}

.label {
    min-height: 18px;
    max-height: 18px;
    border-radius: 20px;
    font-size: 14px;
}

.transactionLabel {
    border: 2px solid $invevo-purple;
    line-height: 1;
}

.customerLabel {
    border: 2px solid $invevo-blue;
    line-height: 1;
}
