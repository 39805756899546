@import "../../scss/variables.scss";

.smallText {
  font-size: 13px;
}

.changeingBorder {
  border: 1px solid transparent;
  border-bottom: 1px solid rgb(189, 186, 186);
  &:hover {
    border: 1px solid $invevo-blue;
  }
}

.coloredBox {
  height: 20px;
  background-image: linear-gradient(to right, #56dd25, #409621);
  border-radius: 2px;
}

.disabledText {
  color: rgb(196, 193, 193);
}

.onHover {
  &:hover {
    background-color: #eff0f5;
    border-radius: 6px;
  }
}
