@import "../../../scss/variables.scss";

.container {
    border-radius: 7px;
    box-shadow: 3px 3px 8px #0080b8, -3px -3px 8px #00adfa;

    :first-child {
        border-radius: 7px 0 0 7px;
    }

    :last-child {
        border-radius: 0 7px 7px 0;
    }

    :first-child:last-child {
        border-radius: 7px;
    }

    :not(:last-child) {
        border-right: solid 1px $invevo-light-grey;
    }
}

.blueButton {
    background-color: $invevo-blue;
    border: none;
    color: white;

    &:hover {
        background-color: $invevo-mid-blue;
    }
}

.options {
    border-radius: 0 0 7px 7px;
    z-index: 1000;
}

.scrollable {
    max-height: 250px;
    overflow-y: auto;
}

.label {
    min-height: 18px;
    max-height: 18px;
    border-radius: 20px;
    font-size: 14px;
}
