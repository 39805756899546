@import "../../../scss/variables.scss";

.columns {
    width: 300px;
    margin-left: -260px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.2), -5px -5px 8px rgba(255, 255, 255, 0.9);
    z-index: 0;
}

.header {
    box-shadow: 0 0 8px rgba(50, 50, 50, 0.2);
    clip-path: inset(0px 0px -8px 0px);
}

.searchInput {
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    border-radius: 7px;
    color: $invevo-text;

    &:focus {
        box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
        color: $invevo-dark-grey;
    }
}

.body {
    max-height: 250px;
    overflow-y: auto;
    color: $invevo-text;
}

.footer {
    box-shadow: 0 0 8px rgba(50, 50, 50, 0.2);
    clip-path: inset(-8px 0px 0px 0px);
}

.dialog {
    z-index: 1000;
}
