@import "../../../scss/variables.scss";

.container {
    background-color: #33abe1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.expanded {
    border-bottom: 1px solid $invevo-light-grey;
}

.optionsContainer {
    min-height: 50px;
}

.option {
    border-radius: 20px;
    background-color: $invevo-background-grey;
    border: 1px solid #8fdbff55;

    &:hover {
        border: 1px solid $invevo-mid-blue;
    }

    &.disabled {
        cursor: not-allowed;
        color: $invevo-grey;
        filter: grayscale(0.5);
    }
}

.selectedOption {
    border: 1px solid $invevo-mid-blue;
    border-radius: 20px;
    background-color: $invevo-mid-blue;
    color: white;
    box-shadow: inset 0 0 5px #0d3b57;

    &.disabled {
        cursor: not-allowed;
        color: $invevo-grey;
        filter: grayscale(0.5);
    }
}
