$invevo-blue: #0096d9;
$invevo-blue-gradient: #4458cc;
$invevo-dark-blue: #2c5797;
$invevo-light-blue: #0094d90c;

$invevo-dark-grey: #4a4a4a;
$invevo-grey: #c1c2cc;
$invevo-light-grey: #d5dae0;
$invevo-background-grey: #eff0f5;

$invevo-text: #556972;

$invevo-purple: #5C2ECD;