.strip {
    min-width: 70px;
    max-width: 70px;
    width: 7vh;
    background-color: #e0e1e5;
    overflow-y: auto;
    overflow-x: hidden;

    .strip-header {
        color: #afb2c5;
    }
}
