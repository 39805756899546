@import '../../../../scss/variables.scss';

.contentContainer {
    width: 33%;
    background-color: $invevo-light-grey;
}

.comingSoon {
    display: flex;
    justify-content: center;
    font-size: 50px;
}

.label{
    border-radius: 7px;
    border: none;
    box-shadow: inset 0 0 10px rgba(50, 50, 50, 0.5);
    color: $invevo-text;
}

.parentContainer {
    position: relative;
}

.detailsContainer {
    position: absolute;
    right: 0;
}