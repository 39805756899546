.custom-grid.k-grid {
  background-color: $mia-grey;
  border-color: #eff0f5;

  tr,
  thead,
  .k-grid-container,
  .k-grid-header,
  .k-grid-footer,
  .k-grid-footer-wrap {
    border: none;
    border-image: none;
  }

  .k-grid-container {
    td,
    th {
      padding: 0 0.5rem;
    }

    .k-grid-table {
      border-spacing: 0 1px;
    }
  }

  .k-master-row,
  .k-master-row.alt {
    background-color: $white;
    td:first-child {
      border-right: none;
    }

    td,
    th {
      padding: 0.5rem;
    }

    td:last-child {
      border-left: none;
    }

    td:not(:last-child):not(:first-child) {
      border-left: none;
      border-right: none;
    }
    &.k-state-selected {
      td {
        border: none;
        background-color: $mia-blue-highlight;
      }
    }
  }
  th {
    background-color: #eff0f5;
    border: none;
    &:last-of-type,
    &.k-hierarchy-cell {
      border-right: none;
    }

    &.k-header.active > div > div {
      background-color: $mia-alert;
      span {
        color: #fff;
      }
    }
  }

  .k-grid-footer {
    background-color: #eff0f5;
    td {
      border: none;
    }
  }
  .k-hierarchy-cell {
    > .k-icon {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  &.custom-grid-action {
    border: none;
    background-color: $mia-action;
    color: $white;
    .k-grid-header,
    .k-master-row,
    .k-master-row.alt {
      background-color: $mia-action;
      color: $white;
      border-bottom: 1px solid $mia-light-blue-text;

      td {
        border-bottom: 1px solid $mia-light-blue-text;
      }
    }
    th {
      background-color: $mia-action;
      color: $white;
    }
    .k-grid-footer {
      background-color: $mia-action;
      color: $white;
    }
  }
}

.k-icon,
.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: $mia-blue;
}
.k-pager-numbers .k-state-selected,
.k-pager-numbers .k-state-selected:hover {
  background-color: $mia-blue-highlight;
  border-color: $mia-blue-highlight;
  color: unset;
}

.k-tabstrip.material {
  .k-state-default .k-link {
    color: $mia-greyer;
    &:hover {
      color: $mia-greyer;
    }
  }

  > .k-content,
  .k-content .k-state-active {
    border: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .k-tabstrip-items {
    background-color: $white;
    border: 0;
    padding-top: 10px;

    span {
      text-align: center;
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
      color: $mia-text;
    }
    .k-state-active > span {
      color: $mia-blue;
    }

    li {
      border: 0;
      border-bottom: 3px solid transparent;
    }

    li.k-item.k-state-active {
      border: 0;
      border-bottom: 3px solid $mia-blue;
    }
  }
}

.k-splitter {
  border: 0;
}

.k-splitter-flex .k-pane {
  min-height: unset;
  height: unset;
}

.single-cell.k-grid {
  border: none;

  .k-header {
    background-color: #888;
  }

  td {
    padding: 5px 8px;
    background-color: $mia-background-grey;
    &.mia-action {
      background-color: $mia-action;
    }
  }

  .k-master-row {
    margin: 5px;
    background-color: $mia-background-grey;
  }

  .k-master-row.alt {
    background-color: $white;
  }

  tr.k-state-selected > td {
    border: 0;
    background-color: transparent;
  }

  .result-default {
    border: 0;
    border-bottom: 3px solid transparent;
  }

  .result-selected {
    border: 0;
    color: $mia-dark-grey;
    background-color: $mia-blue-highlight;
    border-bottom: 3px solid $mia-blue;
  }
}

.k-tabstrip-items .k-link {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 21px;
}

.k-loading-mask {
  min-height: 100px;
}