.task-pane {
  hr {
    color: $white;
  }

  a.dropdown-toggle {
    color: #fff;
    padding-left: 0;
  }
  .nav > li > a:hover,
  .nav > li > a:focus {
    background-color: transparent;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: transparent;
  }
  label {
    margin-bottom: 0;
  }
  textarea {
    color: $mia-text;
    padding: 5px 10px;
  }
  button {
    margin-right: 5px;
  }
  tbody,
  .single-cell.k-grid td,
  .single-cell.k-grid tr {
    background-color: white;
  }
}
