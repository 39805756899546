@import "../../scss/variables.scss";

.head {
    position: sticky;
    top: 0;
    color: white;
    z-index: 2;
    height: 50px;
    text-align: center;
    box-shadow: 0.5rem 0px 1px $invevo-blue, -0.5rem 0px 1px $invevo-blue;
}

.table {
    border-collapse: separate;
    border-spacing: 0px 0.25rem;
}

.stickRight {
    position: sticky;
    right: 0;
    z-index: 1;
}

.headSide {
    background-color: $invevo-blue;
    z-index: 3;
}

.actionButton {
    border: 1px solid white;
    box-shadow: none !important;
}
