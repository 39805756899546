.background {
    background-image: linear-gradient(#455362, #272e35);
}

.steps {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-image: linear-gradient(#1997d7, #4556cc);
    max-width: 600px;
}

.logo {
    max-width: 300px;

    img {
        width: 100%;
    }
}

.header {
    border-top-right-radius: 20px;
}

.form {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.footer {
    border-bottom-right-radius: 20px;
}
