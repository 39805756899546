@import "../../../../../scss/variables.scss";

.label {
    width: 80px;
    padding: 3px;
    border-radius: 20px;
    
    &.green {
        border: 2px solid #28D04A;
        background: rgb(#28D04A, .1); 
    }
    &.blue {
        border: 2px solid $invevo-blue;
        background: rgb($invevo-blue, .1); 
    }
    &.amber {
        border: 2px solid orange;
        background: rgb(orange, .1); 
    }
    &.red {
        border: 2px solid red;
        background: rgb(red, .1); 
    }
}

.text {
    font-size: 9px;
}