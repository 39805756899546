@import "../../scss/variables.scss";

.options {
    border-radius: 0 0 7px 7px;
    z-index: 1000;
    left: 0;
    right: 0;
}

.input-with-matches {
    input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.scrollable {
    max-height: 450px;
    overflow-y: auto;
}

.label {
    min-height: 18px;
    max-height: 18px;
    border-radius: 20px;
    font-size: 14px;
}
