@import "../../../scss/variables.scss";

.dragging {
    box-shadow: 0 0 20px 10px rgba(73, 73, 73, 0.2);
}

.droppable {
    min-width: 18rem;
    flex-basis: 0;
}
