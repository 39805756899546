.collapsed-customer {
    min-width: 70px;
    max-width: 70px;
    width: 7vh;
    z-index: 1;
    box-shadow: 0 5px 6px 5px rgba(0, 0, 0, 0.3);
}

.hide {
    width: 0 !important;
    min-width: 0 !important;
    display: none !important;
}