#documents-panel {
  .result-selected,
  .result-default {
    display: block;
    height: 100%;
  }

  .result-default {
    padding-top: 5px;
    padding-left: 9px;
  }

  // makes it behave like a bootstrap row
  .single-cell tbody {
    border: none;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
  }

  .result-default {
    background-color: $mia-background-grey;
  }

  .k-grid tbody tr.k-state-hover {
    color: #212529;
    background-color: transparent;
  }

  .doc-card {
    .doc-type {
      font-size: 24px;
      line-height: 58px;
      text-align: center;
      color: $mia-blue;
    }

    h3,
    h5 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 0.5rem;
      margin-bottom: 0;
    }
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  &:focus {
    border-color: #2196f3;
  }
}
