.resultCountButtonWidth {
    min-width: 200px;
}

.onHover {
    border-radius: 6px;

    &:hover {
        box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.4), inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    }
}
