@import "../../../scss/variables.scss";

.button {
    &.disabled {
        opacity: 0.5;
    }

    .iconRight {
        border-radius: 0px 7px 7px 0px;
    }

    .iconLeft {
        border-radius: 7px 0px 0px 7px;
    }

    .labelLeft {
        border-radius: 7px 0px 0px 7px;
    }

    .labelRight {
        border-radius: 0px 7px 7px 0px;
    }
}

.button-white {
    .icon {
        background-color: $invevo-blue;
        color: white;
    }

    .label {
        color: $invevo-text;
        background-color: white;
        border: 1px solid $invevo-blue;
    }

    &:hover:not(.disabled) {
        .label {
            color: white;
            background-color: $invevo-blue;
        }
    }
}

.button-blue {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.4), -5px -5px 8px rgba(255, 255, 255, 0.4);
    border-radius: 7px;

    .icon {
        color: white;
        background-color: $invevo-blue-faded;
        width: 4rem;
    }

    .label {
        color: white;
        background-color: $invevo-blue;
    }

    &:hover:not(.disabled) {
        .icon {
            color: $invevo-blue;
            background-color: #ddf1fa;
        }

        .label {
            color: $invevo-blue;
            background-color: white;
        }
    }
}

.button-red {
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.4), -5px -5px 8px rgba(255, 255, 255, 0.4);
    border-radius: 7px;

    .icon {
        color: white;
        background-color: $invevo-red-faded;
        width: 4rem;
    }

    .label {
        color: white;
        background-color: $invevo-red;
    }

    &:hover:not(.disabled) {
        .icon {
            color: $invevo-red;
            background-color: #fadddd;
        }

        .label {
            color: $invevo-red;
            background-color: white;
        }
    }
}

.button-grey {
    color: $invevo-text;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.2), -5px -5px 7px rgba(255, 255, 255, 1);
    border-radius: 7px;
    background-color: $invevo-background-grey;

    &.border-blue {
        border: 1px solid $invevo-blue;
    }

    .icon {
        color: $invevo-text;
        width: 4rem;
    }
}

.smallButton {
    line-height: 1;
    font-size: 1rem;
}
