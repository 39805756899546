@import '../../scss/variables.scss';

.item {

    i {
        background-color: transparent;
    }
}

.disabled {
    color: #9E9E9E;
}

.active {
    background-color: $invevo-blue  !important;
}

.label {
    background-color: #ffffffdd !important;
    animation: slideIn 0.2s ease-in;
    color: $invevo-text;
    box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    z-index: 9999;
}

@keyframes slideIn {
    from {
        background-color: transparent;
        color: transparent;
        box-shadow: none
    }

    to {
        background-color: #ffffffdd;
        color: $invevo-text;
        box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    }
}