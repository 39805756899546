@import "../../scss/variables.scss";

.container {
  background-color: $invevo-blue;
  min-width: 360px;
  max-width: 360px;
  height: 100%;
  overflow: auto;
}

.textLabel {
  color: white;
  font-size: 18;
}

.divider {
  border-top: 1px solid white;
  margin-top: 20px;
  margin-bottom: 20px;
}
