@import "../../../scss/variables.scss";

.selectedFilter {
    border: 1px solid $invevo-mid-blue;
    border-radius: 20px;
    background-color: $invevo-mid-blue;
    color: white;
    box-shadow: inset 0 0 5px #0d3b57;
}

.blueButton {
    border-radius: 7px;
    box-shadow: 3px 3px 8px #0080b8, -3px -3px 8px #00adfa;
    background-color: $invevo-blue;
    border: none;
    color: white;

    &:hover {
        background-color: $invevo-mid-blue;
    }
}

.respectActionButtons {
    margin-right: 10rem;
}
