@import "../../../scss/variables.scss";

.slide {
    width: 350px;
    top: 0;
    right: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 10;
    transition: width 2s;
    animation: slideIn 0.2s ease-out;
    position: relative;
}

@keyframes slideIn {
    from {
        right: -350px;
    }

    to {
        right: 0px;
    }
}

.container {
    min-height: 50px;
}

.option {
    border: 1px solid #e3e4e9;
    border-radius: 20px;
    background-color: #e3e4e9;

    &:hover {
        border: 1px solid $invevo-blue;
        background-color: #beeaff55;
    }
}

.selectedOption {
    border: 1px solid #1874aa;
    border-radius: 20px;
    background-color: #1874aa;
    color: white;
    box-shadow: inset 0 0 5px #0d3b57;
}

.closeButton {
    height: 30px;
    width: 30px;
    border: 1px solid white;
}
