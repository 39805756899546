@import "../../../scss/variables.scss";

.title {
  color: $invevo-text;
  background-color: transparent;
  border-radius: 7px;
}

.title:not(.collapsed) {
  color: $invevo-text;
  background-color: transparent;
  box-shadow: none;
}

.container {
  border-radius: 7px;
  border: 1px solid $invevo-blue;
}

.workflow-run-result-table {
  max-height: 500px;
}
