@import "../../scss/app.scss";

.legend-colour-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.label-white {
    color: #9e9e9e;
}

.item-value {
    margin-top: -10px;
}