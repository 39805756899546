@import "../../../scss/variables.scss";

.card {
    min-width: 400px;
    max-width: 400px;
}

.profile-picture {
    border: 1px solid #52C8B0;
    border-radius: 50%;
    border-width: 2px;
    width: 50px;
    max-width: 50px;
    height: 50px;
    max-height: 50px;
}

.bg-darker-grey {
    background-color: #e5e5e5;
}

.rounded {
    border-radius: 20px;
}

.shadow {
    box-shadow: 0 0 12px 2px #dedede;
}

.icon-inactive {
    color: $invevo-background-grey;
}

.filter-actions {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: $invevo-light-blue;
}