@import "../../../scss/variables.scss";

.options-wrapper {
  height: 50rem;
}

.options {
  overflow-x: hidden;
  z-index: 1000;
  max-height: 50%;
  min-width: 60px;
}

.options-above {
  border-radius: 7px 7px 0 0;
  bottom: 50rem;
}

.options-below {
  border-radius: 0 0 7px 7px;
}

.parameters {
  min-height: 36px;
  max-height: 36px;
  border-radius: 7px;
  border: none;
  box-shadow: inset 0 0 10px rgba(50, 50, 50, 0.5);
  color: $invevo-text;

  &:focus {
    color: $invevo-dark-grey;
  }
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
}
