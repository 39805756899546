@import "../../../scss/variables.scss";

.card {
    border: solid 1px $invevo-blue;
    box-shadow: 0 0 5px 2px #00000020;
}

.widget-list {
    height: 150px;
    max-height: 150px;
    background-color: #f8f8f8;
}

.button {
    border: solid 1px $invevo-blue;
    border-radius: 7px;
}

.widget-name {
    max-width: 100%;
}

.assign-roles-icon {
    font-size: x-small;
}