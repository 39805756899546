@import '../../scss/variables.scss';

.input {
    min-width: 25ch;
    max-width: 50ch;
}

.timezone {
    min-width: 20ch;
}

.box {
    flex: 30ch;
}

.dropdown span {
    text-transform: uppercase;
}