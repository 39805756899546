.container {
    background-color: #0096d3;
}

.callButton {
    width: 70px;
    height: 40px;
    border-radius: 7px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .3), -5px -5px 8px rgba(255, 255, 255, .3);
}

.startCallButton {
    @extend .callButton;
    background-color: #28D04A;

    &:hover {
            background-color: #0AB62C;
        }
}

.endCallButton {
    @extend .callButton;
    background-color: #FA1515;

    &:hover {
            background-color: #FA1515;
        }
}
