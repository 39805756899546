.chevron {
    transition: transform 0.25s linear;
}

.rotated {
    transform: rotate(90deg);
}

.collapsable {
    max-height: 100%;
    transition: max-height 0.15s linear;
    overflow: hidden;
}

.expanded {
    max-width: fit-content;
}

.collapsed {
    max-height: 0;
    transition: max-height 0s linear;
}
