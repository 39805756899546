.search-list-toolbar {
  padding: 5px 10px;
  background-color: $mia-background-grey;
  border-bottom: 3px solid $mia-light-grey;

  h3 {
    line-height: 38px;
    margin: 0;
  }
}

/* Search */
.search-container {
  .search-criteria {
    max-width: 500px;
  }
  overflow: auto;
  background-color: $mia-background-grey;
}
