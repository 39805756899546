@import "../../../scss/variables.scss";

button {
    all: unset;
}

.iconButton {
    min-width: var(--size);
    width: var(--size);
    min-height: var(--size);
    height: var(--size);

    transition: all 0.1s ease-in-out;
    border-radius: 0.5rem;
    background-color: inherit;

    &.disabled {
        opacity: 0.5;
    }
}

.icon-only {
    color: $invevo-text;
}

.white {
    background: white;
    border: 1px solid #1c95d734;
    color: $invevo-text;
}

.white-flat {
    background: white;
    border: 1px solid #1c95d734;
    color: $invevo-blue;
    .count {
        color: $invevo-text;
    }
}

.blue-flat:hover:not(.disabled).animatedHover {
    background: white;
    color: $invevo-blue;
}

.blue-flat {
    background: $invevo-blue;
    border: 1px solid white;
    color: white;
    .count {
        color: white;
    }
}

.blue:active:not(.disabled) {
    box-shadow: 0.5px 0.5px 0.5px 0.5px #00adfa, -0.5px -0.5px 0.5px 0.5px #0080b8;
}

.blue {
    background: $invevo-blue;
    box-shadow: 0.15rem 0.15rem 0.3rem 0.15rem #0080b8, -0.15rem -0.15rem 0.3rem 0.15rem #00adfa;
    color: white;
}

.grey {
    color: $invevo-text;
    background-color: $invevo-background-grey;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.2), -5px -5px 8px rgba(255, 255, 255, 0.5);
}

.mid-blue:active:not(.disabled) {
    box-shadow: 0.5px 0.5px 0.5px 0.5px #1c85c4, -0.5px -0.5px 0.5px 0.5px #146391;
}

.mid-blue {
    background: $invevo-mid-blue;
    box-shadow: 0.15rem 0.15rem 0.3rem 0.15rem #146391, -0.15rem -0.15rem 0.3rem 0.15rem #1c85c4;
    color: white;
}

.dynamic-shadow {
    box-shadow: 0.15rem 0.15rem 0.3rem 0.15rem #00000023, -0.15rem -0.15rem 0.3rem 0.15rem #ffffff1a;
}

.dynamic-shadow:active:not(.disabled) {
    box-shadow: 0.5px 0.5px 0.5px 0.5px #ffffff1a, -0.5px -0.5px 0.5px 0.5px #00000023;
}

.small {
    --size: 2rem;
}

.medium {
    --size: 2.5rem;
}

.large {
    --size: 3rem;
}
