@import "../../../scss/variables.scss";

.role-card {
    border: 1px solid $invevo-blue;
    min-width: 400px;
    max-width: 400px;
}

.header {
    border-bottom: 1px solid #0094d981;
}

.shadow {
    box-shadow: 0 0 12px 2px #dedede;
}

.bg-darker-grey {
    background-color: #e5e5e5;
}

.scrolling-form {
    overflow-x: hidden;
    overflow-y: auto;
}

.assign-users-form {
    width: 700px;
}

.rounded {
    border-radius: 20px;
}

.icon-inactive {
    color: $invevo-background-grey;
}

.filter-actions {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: $invevo-light-blue;
}