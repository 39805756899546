@import '../../scss/variables.scss';

.shadow {
    box-shadow: 0 0 12px 1px #00000052;
}

.rounded {
    border-radius: 20px;
}

.dropOver {
    position: relative;
    top: -30px;
    height: 0px;
    z-index: 100;
}