@import "../../../../scss/variables.scss";

.periods {
    box-shadow: 0px 10px 15px #d4d4d8 inset;
}

.isSelected {
    background-color: $invevo-blue;
    box-shadow: 5px 5px 10px #d4d4d8;

    span {
        color: white !important;
    }
}
