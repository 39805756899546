@import "../../../scss/variables.scss";

.role-card {
    border: 1px solid $invevo-purple;
    background-color: #F9F7FD;
    min-width: 400px;
    max-width: 400px;
}

.header {
    border-bottom: 1px solid #5b2ecd7c;
}

.rounded {
    border-radius: 20px;
}

.bg-darker-grey {
    background-color: #e5e5e5;
}

.scrolling-form {
    overflow-x: hidden;
    overflow-y: auto;
}

.assign-users-form {
    width: 700px;
}

.shadow {
    box-shadow: 0 0 12px 2px #dedede;
}