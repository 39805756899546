#contact-panel {
  .result-selected,
  .result-disabled,
  .result-default {
    display: block;
    height: 100%;
  }

  .result-default {
    background-color: $mia-background-grey;
  }

  .result-disabled {
    opacity: 0.5;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none; 
  }

  .single-cell tbody {
    border: none;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
  }

  .k-grid tbody tr.k-state-hover {
    color: #212529;
    background-color: transparent;
  }
}
