.btn-custom {
    color: #fff !important;
    border-color: #fff;
    background-color: unset;

    &:hover {
        color: #fff;
        border-color: #0096d9;
        background-color: #2c5797;
    }
}

.container {
    background-color: #0096d3;
    color: white;
}