@import "../../scss/variables.scss";

.disabled {
    opacity: 0.8;
}

.button {
    min-height: 36px;
    max-height: 36px;
    border-radius: 7px;
    box-shadow: 3px 3px 4px rgba(50, 50, 50, .4), -3px -3px 4px rgba(255, 255, 255, .4);
    transition: border-radius 0.05s 0.3s;
}

.whiteBackground {
    background-color: $invevo-background-grey;
    color: $invevo-text;
    .chevron {
        color: $invevo-blue;
    }
}

.blueBackground {
    background-color: $invevo-blue;
    color: white;
}

.onTop {
    z-index: 100;
}

.selectedUpwardsButton {
    transition: border-radius 0s;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.selectedDownwardsButton {
    transition: border-radius 0s;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.chevron {
    transition: transform 0.25s ease-out;
}

.selectedUpwardsChevron {
    transform: rotate(-90deg);
}

.selectedDownwardsChevron {
    transform: rotate(90deg);
}

.options {
    max-height: 0px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .4), -5px -5px 8px rgba(255, 255, 255, .4);
    transition: max-height 0.3s ease-out;
    z-index: 1000;
}

.selectedOptions {
    max-height: 300px;
}

.upwardsOptions {
    bottom: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.downwardsOptions {
    top: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.fixedSize {
    min-width: 210px;
    max-width: 210px;
}