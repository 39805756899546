@import "../../../scss/variables.scss";

.head {
    position: sticky;
    top: 0;
    color: white;
    background-color: $invevo-blue;
    border-right: 10px solid $invevo-blue;
    z-index: 2;
    height: 50px;
    text-align: center;
}

.container {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.stickRight {
    position: sticky;
    right: 0;
    z-index: 1;
}

.headSide {
    background: $invevo-blue;
    z-index: 3;
}

.actionButton {
    border: 1px solid white;
    box-shadow: none !important;
}
