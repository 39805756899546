@import "../../scss/variables.scss";

.tableRow {
    background-color: #cfe2e9;
    box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.4);

    &:hover {
        box-shadow: 0px 0px 5px rgba(50, 50, 50, 0.4), inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    }
}

.firstCell {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.lastCell {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.background {
    background-color: white;
}

.dataItem {
    color: $invevo-text;
}

.rowActionButton {
    background-color: #ffffffbf;
    border: 1px solid #1c95d734 !important;
    box-shadow: none !important;
}

.stickRight {
    position: sticky;
    right: 0;
    z-index: 1;
}
