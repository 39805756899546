.admin-pages {
  min-width: calc(100% - 60px);
  .form-control {
    max-width: 480px;
  }

  .nav-item {
    padding: 5px 5px 5px 0;
  }
}
