.background {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999999;
}

.overlay {
    display: inline-block;
    margin: 10vh;
}