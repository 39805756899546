@import "../../../../scss/variables.scss";

.separator {
    opacity: 0.3;
    height: 1px;
}

.node {
    border-radius: 2px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
}

.nodeTopBorder {
    min-height: 0.5rem;
}

.description {
    width: 0;
    min-width: 100%;
}
