@import "../../scss/variables.scss";

.tab-button {
  border-color: $invevo-blue;
}

.bubbleTab {
  border-radius: 20px;
  border-style: none;
  background-color: #eff0f5;
  color: #55697a;
}

.activeTab {
  background-color: #ddf1fa;
  color: #0096d9;
}

.separator {
  min-height: 1px;
}
