.trans-grid-actions {
  background-color: $mia-background-grey;
  margin-top: 20px;

  .btn-group {
    width: 100%;
  }
}

.transaction-grid {
  min-height: 450px;
}

.grid-actions button {
  margin-left: 8px;
}
