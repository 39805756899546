@import "../../../scss/variables.scss";

.changeingBorder {
  border: 1px solid transparent;
  border-bottom: 1px solid rgb(189, 186, 186);
  &:hover {
    border: 1px solid $invevo-blue;
  }
}

.coloredBox {
  height: 30px;
  background-image: linear-gradient(to right, #56dd25, #409621);
  border-radius: 2px;
}

.warningBox {
  height: 30px;
  background-color: orange;
  border-radius: 2px;
  border: 2px solid black;
}

.disabledText {
  color: rgb(196, 193, 193);
}

.workflow-outcomes-result-table {
  max-height: 500px;
}

.container {
  border-radius: 7px;
  border: 1px solid $invevo-blue;
}