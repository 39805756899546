.container {
    right: 0;
}

.strip {
    min-width: 60px;
    max-width: 70px;
    width: 4vw;
    background-image: linear-gradient(#BEEAFF, #1DA0DB, #6227CD);
    box-shadow: 0 5px 6px 5px rgba(0, 0, 0, 0.2);

    .item:nth-child(1) {
        background: mix(mix(#BEEAFF, #1DA0DB, (100 - (0 * 18.18))), #6227CD, 100%);
    }

    .item:nth-child(2) {
        background: mix(mix(#BEEAFF, #1DA0DB, (100 - (1 * 18.18))), #6227CD, 100%);
    }

    .item:nth-child(3) {
        background: mix(mix(#BEEAFF, #1DA0DB, (100 - (2 * 18.18))), #6227CD, 100%);
    }

    .item:nth-child(4) {
        background: mix(mix(#BEEAFF, #1DA0DB, (100 - (3 * 18.18))), #6227CD, 100%);
    }

    .item:nth-child(5) {
        background: mix(mix(#BEEAFF, #1DA0DB, (100 - (4 * 18.18))), #6227CD, 100%);
    }

    .item:nth-child(6) {
        background: mix(mix(#BEEAFF, #1DA0DB, (100 - (5 * 18.18))), #6227CD, 100%);
    }

    .item:nth-child(7) {
        background: mix(mix(#BEEAFF, #1DA0DB, 0%), #6227CD, (100 - (0 * 18.18)));
    }

    .item:nth-child(8) {
        background: mix(mix(#BEEAFF, #1DA0DB, 0%), #6227CD, (100 - (1 * 18.18)));
    }

    .item:nth-child(9) {
        background: mix(mix(#BEEAFF, #1DA0DB, 0%), #6227CD, (100 - (2 * 18.18)));
    }

    .item:nth-child(10) {
        background: mix(mix(#BEEAFF, #1DA0DB, 0%), #6227CD, (100 - (3 * 18.18)));
    }

    .item:nth-child(11) {
        background: mix(mix(#BEEAFF, #1DA0DB, 0%), #6227CD, (100 - (4 * 18.18)));
    }

    .item:nth-child(12) {
        background: mix(mix(#BEEAFF, #1DA0DB, 0%), #6227CD, (100 - (5 * 18.18)));
    }
}

.action {
    max-height: 100vh;
}

.show {
    animation: slideIn 0.2s ease-out;
    width: 35vw;
}

.hide {
    animation: slideOut 0.2s ease-out;
    width: 0;
    padding: 0 !important;
}

.close {
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 7px;
}

@keyframes slideIn {
    from {
        width: 0;
    }

    to {
        width: 35vw;
    }
}

@keyframes slideOut {
    from {
        width: 35vw;
    }

    to {
        width: 0;
    }
}