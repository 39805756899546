.container {
    border: 1px solid #0096d9;
}

.header {
    border-bottom: 1px solid #BCBCBC;
}

.add-rule-button {
    border-right: 1px solid #BCBCBC;
}

.header-icon {
    color: #BCBCBC;
}