@import "../../../scss/variables.scss";

.input {
  border-radius: 7px;
  border: 1px solid $invevo-grey;
  color: $invevo-text;
  background-color: #f8f8f8;
  padding: 0.25rem;

  &:focus {
    border: 1px solid $invevo-grey;
    box-shadow: none;
    color: $invevo-dark-grey;
    background-color: #f8f8f8;
    padding: 0.25rem;
  }
}

.invalid {
    border: 1px solid $invevo-red;
  }