@import "../../scss/variables.scss";

.container {
    min-width: 190px;
    min-height: 220px;
    max-width: 190px;
    max-height: 220px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.4), -5px -5px 8px rgba(255, 255, 255, 0.4);
    border-radius: 10px;
}

.iconContainer {
    height: 165px;
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(0deg, $invevo-blue-gradient, $invevo-blue);
    font-size: x-large;

    border-top: solid 2px $invevo-blue;
    border-left: solid 2px transparent;
    border-right: solid 2px transparent;

    &.hovering {
        border-top: solid 2px $invevo-blue-gradient;
        border-left: solid 2px $invevo-blue-gradient;
        border-right: solid 2px $invevo-blue-gradient;
    }
}

.textContainer {
    border-radius: 0px 0px 10px 10px;
    background-color: $invevo-background-grey;
    height: 55px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #556972;

    border-bottom: solid 2px transparent;
    border-left: solid 2px transparent;
    border-right: solid 2px transparent;

    &.hovering {
        border-bottom: solid 2px $invevo-blue;
        border-left: solid 2px $invevo-blue;
        border-right: solid 2px $invevo-blue;
    }
}

.objectContain {
    object-fit: contain;
}
