@import "../../../scss/variables.scss";

.slide {
    width: 400px;
    position: absolute;
    top: 0;
    right: 0;
    transition: width 2s;
    animation: slideIn 0.2s ease-out;
    box-shadow: 0 5px 6px 5px rgba(180, 180, 180, 0.603);
    z-index: 10;
}

@keyframes slideIn {
    from {
        right: -400px;
    }

    to {
        right: 0px;
    }
}

.option {
    border: 1px solid #e3e4e9;
    border-radius: 20px;
    background-color: #e3e4e9;

    &:hover {
        border: 1px solid $invevo-blue;
        background-color: #BEEAFF55;
    }
}

.selectedOption {
    border: 1px solid #1874aa;
    border-radius: 20px;
    background-color: #1874aa;
    color: white;
    box-shadow: inset 0 0 5px #0d3b57;
}