.menu {
    min-width: 70px;
    max-width: 90px;
    width: 4vw;
    background-image: linear-gradient(#1997d7, #4556cc);
    z-index: 1001;
}

.mobile-menu {
    width: 100%;
    z-index: 1;
    background-image: linear-gradient(#1997d7, #4556cc);
}

.logo {
    width: 45px;
    margin-top: 10px;
}

.mobile-logo {
    img {
        width: 180px;
    }
}

.mobile-overlay {
    position: fixed;
    top: 0px;
    left: 10%;
    width: 90%;
    height: 100%;
    z-index: 3;
    background-image: linear-gradient(#1997d7, #4556cc);
}

.mobile-menu-items {
    width: 100%;
    height: 100%;

    div {
        border-bottom: 2px solid #455362;
    }
}

.mobile-shadow {
    box-shadow: 0rem 1rem 3rem 1rem #858586
}