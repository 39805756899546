@import '../scss/variables.scss';

$invisibleColour: rgba(255, 255, 255, 0);
$hoverColour: $invevo-blue;

.iconContainer {
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(0deg, $invevo-blue-gradient, $invevo-blue);
    font-size: x-large;

    border-top: solid 2px $hoverColour; // Top border is set to this colour because a css bug with gradients colours this incorrectly
    border-left: solid 2px $invisibleColour;
    border-right: solid 2px $invisibleColour;

    &.hovering {
        border-top: solid 2px $hoverColour;
        border-left: solid 2px $hoverColour;
        border-right: solid 2px $hoverColour;
    }
}

.textContainer {
    border-radius: 0px 0px 10px 10px;
    background-color: $invevo-background-grey;
    box-shadow: 0px 4px 0px 0px $invevo-grey;
    min-height: 55px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #556972;

    border-bottom: solid 2px $invisibleColour;
    border-left: solid 2px $invisibleColour;
    border-right: solid 2px $invisibleColour;

    &.hovering {
        border-bottom: solid 2px $hoverColour;
        border-left: solid 2px $hoverColour;
        border-right: solid 2px $hoverColour;
        box-shadow: 0px 4px 0px 0px $hoverColour;
    }
}