@import "../../../../../scss/variables.scss";

.profile-picture {
    border: 1px solid #52C8B0;
    border-radius: 50%;
    border-width: 2px;
    width: 30px;
    max-width: 30px;
    height: 30px;
    max-height: 30px;
}

.container {
    height: 60px;
    background-color: $invevo-background-grey;
}

.heatmapButton {
    background: none;
    border: none;
}