.body {
    background-image: linear-gradient(#455362, #272e35);
}

.header {
    background-image: linear-gradient(#1997d7, #4556cc);
}

.logo {
    max-width: 200px;

    img {
        width: 100%;
    }
}
