@import "../../../scss/variables.scss";

.sidebar {
    width: 20vw;
    min-width: 350px;
}

.container {
    box-shadow: 0 5px 6px 5px rgba(180, 180, 180, 0.603);
    z-index: 1;
}

.gridBackground {
    background-image: $invevo-background-gradient-dark;
}

.filterCount {
    left: calc(100% - 18px);
    top: -8px;
    height: 26px;
    width: 26px;
    line-height: 26px;
}

.advancedFiltersContainer {
    border-top-right-radius: 0 !important;
}

.quickSearchContainer {
    border: solid 1px $invevo-blue;
    border-radius: 7px;

    .quickSearchInputButton:last-child {
        border-radius: 0 7px 7px 0;
    }

    .quickSearchInputButton:not(:last-child) {
        border-right: solid 1px $invevo-light-grey;
    }
}

.quickSearchInput {
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    border-radius: 7px 0 0 7px;
    color: $invevo-text;

    &:focus {
        box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
        color: $invevo-dark-grey;
    }
}

.quickSearchInputButton {
    background-color: $invevo-blue;
    border: solid 1px $invevo-blue;
    color: white;

    &:hover {
        background-color: $invevo-mid-blue;
    }
}
