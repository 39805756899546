@import "../../../../../../scss/variables.scss";

.card {
    background-color: $invevo-background-grey;
}

.verticalDivider {
    border-right: 1px solid $invevo-light-grey;
}

.horizontalDivider {
    border-top: 1px solid $invevo-light-grey;
}
