.positive {
  color: green;
}

.negitive {
  color: red;
}

.hero {
  font-size: 20px;
}

.bg-highlight {
  background-color: $mia-blue-highlight;
}

#account-summary {
  .card-columns {
    font-size: 0.875rem;
    column-gap: 0.25rem;
  }
}
