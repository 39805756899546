@import './variables.scss';

.text-white {
    color: white;
}

.text-light-blue {
    color: #c2e6f5;
}

.text-red {
    color: #ee4056
}

.text-orange {
    color: #ffa10b
}

.text-light-grey {
    color: #8896a2;
}

.k-grid-table {
    width: 100%;
}

.top-nav {
    .k-tabstrip-items-wrapper {
        margin-left: auto;
    }

    #documents-panel {
        .k-tabstrip-items-wrapper {
            margin-left: 0 !important;
        }
    }
}

.k-animation-container {
    z-index: 1000 !important;
}

.form-group {
    margin-bottom: 1rem;
}

.admin-pages .form-control {
    max-width: 480px;
}

.card-columns .card {
    margin-bottom: .75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count:3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}