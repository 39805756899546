@import "../../../scss/variables.scss";

.shadow {
    box-shadow: 0 0 12px 2px #dedede;
}

.rounded {
    border-radius: 20px;
}

.icon-inactive {
    color: $invevo-background-grey;
}