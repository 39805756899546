@import '../../scss/variables.scss';

.container {
    background-color: $invevo-blue;
    min-width: 360px;
    max-width: 360px;
}

.glossary {
    background-color: #1aa1dd;

    .glossary-text {
        color: #c4e7f6;
    }
}