@import "../../../scss/variables.scss";

.boundHeight {
    min-height: 2rem;
    max-height: 4rem;
}

.menuItem {
    border-left: 3px solid transparent;
    padding-right: 3px;
}

.active {
    background-color: #00000033;
    border-left: 3px solid #fff;
    box-shadow: inset -1px 10px 5px -3px #00000033, inset -1px -10px 5px -3px #00000033;
}

.options {
    background-color: #ffffffd7;
    animation: slideIn 0.2s ease-in;
    color: $invevo-blue;
    box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    z-index: 9999;
}

@keyframes slideIn {
    from {
        background-color: transparent;
        color: transparent;
        box-shadow: none;
    }

    to {
        background-color: #ffffffd7;
        color: $invevo-blue;
        box-shadow: 5px 5px 10px 3px #00000011, 5px -5px 10px 3px #00000011;
    }
}
