@import "../../../../scss/variables.scss";

.container {
    border-bottom: 1px solid $invevo-background-grey;
}

.selectedFilter {
    border: 1px solid $invevo-mid-blue;
    border-radius: 20px;
    background-color: $invevo-mid-blue;
    color: white;
    box-shadow: inset 0 0 5px #0d3b57;
}

.clearFiltersButton {
    box-shadow: 0 0 2px #0d3b57;
}
