@import "../../../scss/variables.scss";

.button {
    border-radius: 7px;
    box-shadow: 3px 3px 8px rgba(50, 50, 50, 0.4), -3px -3px 5px rgba(255, 255, 255, 0.4);
    
    &:hover:not(.disabled) {
        .label {
            color: white;
            background-color: #30a8df;
        }
        
        .icon, .iconSmall {
            background-color: $invevo-blue;
            color: white;
        }
    }
}

.disabled {
    opacity: 0.5;
}

.iconSmall {
    background-color: #e3e4e9;
    border-radius: 7px 0 0 7px;
    width: 30px;
    color: $invevo-blue;
}

.icon {
    background-color: #e3e4e9;
    border-radius: 7px 0 0 7px;
    width: 46px;
    color: $invevo-blue;
}

.label {
    border-radius: 0 7px 7px 0;
    color: $invevo-text
}