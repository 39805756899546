.panel {
    min-width: 400px;
    max-width: 400px;
}

.filter-container {
    background-color: #0087c3;
    border-radius: 7px;
    box-shadow: inset 0 0 10px #00000010;
}
