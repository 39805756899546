.bg-green {
    background-color: #e6faf6;
}

.bg-purple {
    background-color: #eee9fa;
}

.bg-blue {
    background-color: #e9f2fb;
}

.bg-yellow {
    background-color: #ffff99;
}

.bg-pink {
    background-color: #fcd4dd;
}
