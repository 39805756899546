$invevo-blue: #0096d9;
$invevo-blue-faded: #149edc;
$invevo-blue-gradient: #4458cc;
$invevo-mid-blue: #1874aa;
$invevo-dark-blue: #2c5797;
$invevo-light-blue: #90cfeb;

$invevo-dark-grey: #4a4a4a;
$invevo-grey: #c1c2cc;
$invevo-light-grey: #d5dae0;
$invevo-background-grey: #eff0f5;

$invevo-text: #556972;

$invevo-red: #ee4056;
$invevo-red-faded: #ee556a;

$invevo-purple: #5c2ecd;

$invevo-background-gradient-dark: linear-gradient(#455362, #2c3136);
