@import "../../scss/app.scss";

.value {
    margin-top: -0.625rem;
}

.percentage {
    width: 112px;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid;
    border-radius: 100%;
}

.good {
    border-color: #0fc9a4;
}

.bad {
    border-color: #ee4057;
}

.widget-white {
    min-width: 300px;
    background: white;
}

.widget-blue {
    min-width: 300px;
    background-image: linear-gradient(#1997d7, #4655cc);
}

.text-white-info {
    @extend .text-black;
}

.text-blue-info {
    color: white;
}

.text-white-good {
    color: #0fc9a4;
}

.text-blue-good {
    color: #0fc9a4;
}

.text-white-bad {
    color: #ee4057
}

.text-blue-bad {
    color: #ee4057
}