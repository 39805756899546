@import '../../scss/variables.scss';

.columns {
    width: 300px;
    margin-left: -260px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .2), -5px -5px 8px rgba(255, 255, 255, .9);
    z-index: 0;
}

.button{
    div {
        border-bottom-left-radius: 0px; 
        border-bottom-right-radius: 0px;
    }
}

.header {
    height: 35px;
    box-shadow: 0 0 8px rgba(50, 50, 50, .2);
    clip-path: inset(0px 0px -8px 0px);
}

.body {
    max-height: 250px;
    overflow-y: auto;
    color: $invevo-text;
}

.footer {
    box-shadow: 0 0 8px rgba(50, 50, 50, .2);
    clip-path: inset(-8px 0px 0px 0px);
}

.actionButton {
    border: 1px solid white;
    box-shadow: none !important;
}