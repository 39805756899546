@import '../../../scss/variables.scss';

.smsContainer {
    min-width: 400px; 
    max-width: 400px; 
    overflow-y: auto;
    margin: 0 auto;
}

.dropdown{
    z-index: 1001;
}