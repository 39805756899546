@import '../../../scss/variables.scss';

.card {
    background-color: $invevo-blue;
    flex-grow: 1;
    min-height: 60px;
}

.card h3 {
    color: white !important;
}

.active {
    background-color: $invevo-dark-blue;
}

.resetBoxShadow {
    box-shadow: 0px 0px 0px !important;
}