@import "../../scss/variables.scss";

.options {
  overflow-x: hidden;
  z-index: 1000;
  max-height: 50%;
  min-width: 60px;
}

.optionsBelow {
  border-radius: 0 0 7px 7px;
}

.parameters {
  min-height: 36px;
  max-height: 36px;
  border-radius: 7px;
  border: none;
  box-shadow: inset 0 0 10px rgba(50, 50, 50, 0.5);
  color: $invevo-text;
  overflow-y: auto;
  &:focus {
    color: $invevo-dark-grey;
  }
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
}

.tag {
  min-height: 24px;
  max-height: 24px;
  border-radius: 20px;
}

.tagDelete {
  color: rgba(74, 74, 74, 0.3);
  transition-duration: 500ms;

  &:hover {
    color: $invevo-red;
  }
}