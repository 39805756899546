@import "../../../scss/variables.scss";

.card {
    box-shadow: 0 0 5px 2px #00000020;
}

.remove-icon {
    color: #dddddd;
    
    &:hover {
        color: $invevo-blue;
    }
}