@import "../../../../scss/variables.scss";

.background {
    background-image: $invevo-background-gradient-dark;
}

.quickSearchContainer {
    border: solid 1px $invevo-blue;
    border-radius: 7px;

    .quickSearchInputButton:last-child {
        border-radius: 0 7px 7px 0;
    }

    .quickSearchInputButton:not(:last-child) {
        border-right: solid 1px $invevo-light-grey;
    }
}

.quickSearchInput {
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
    border-radius: 7px 0 0 7px;
    color: $invevo-text;

    &:focus {
        box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.5);
        color: $invevo-dark-grey;
    }
}

.quickSearchInputButton {
    background-color: $invevo-blue;
    border: solid 1px $invevo-blue;
    color: white;

    &:hover {
        background-color: $invevo-mid-blue;
    }
}
