@import '../../../../scss/variables.scss';

.container {
    background-color: $invevo-blue;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, .2), -5px -5px 8px rgba(255, 255, 255, .2);
    border-radius: 7px;
    width: 270px;
}

.button {
    border-radius: 7px;
    box-shadow: 5px 5px 8px rgba(50, 50, 50, 0.2), -5px -5px 8px rgba(255, 255, 255, 0.2);
    line-height: 1;
    font-size: 1rem;

    .icon {
        background-color: #0AB62C;
        color: white;
        border-radius: 7px 0px 0px 7px;
    }

    .label {
        color: white;
        background-color: $invevo-blue;
        border-radius: 0px 7px 7px 0px;
    }

    &:hover {
        .icon {
            color: $invevo-blue;
            background-color: #ddf1fa;
            border-radius: 7px 0px 0px 7px;
        }

        .label {
            color: $invevo-blue;
            background-color: white;
            border-radius: 0px 7px 7px 0px;
        }
    }
}