.widget-white {
    box-shadow: 3px 3px 12px rgba(50, 50, 50, 0.6), -3px -3px 12px rgba(255, 255, 255, 0.2);
    background: linear-gradient(#ffffff, #d3d3d3);
}

.widget-blue {
    box-shadow: 3px 3px 12px rgba(50, 50, 50, 0.6), -3px -3px 12px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(#1997d7, #4655cc);
}

.chart {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.chart-container {
    position: relative;
    width: 80%;
}

.pie-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 80%;
}
.pie-details {
    height: 100%;
    overflow-y: auto;
}
.square {
    display: inline-block;
    position: relative;
    width: 50%;
}

.square:after {
    content: '';
    display: block;
    margin-top: 100%;
}