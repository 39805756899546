@import "../../../../scss/variables.scss";

.container {
    box-shadow: 3px 3px 12px rgba(50, 50, 50, 0.6), -3px -3px 12px rgba(255, 255, 255, 0.2);
    z-index: 1;
}

.sidebar {
    width: 30vw;
    min-width: 30%;
}
