@import '../../scss/variables.scss';

.input-wrapper {
    border-radius: 15px;
    box-shadow: inset 2px 2px 5px 1px rgba(50, 50, 50, 0.25);
    color: $invevo-text;

    &:focus {
        color: $invevo-dark-grey;
    }
}

.input {
    border: none;
    outline: none;
    background-color: transparent;
}