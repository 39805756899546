.admin-items {
  list-style-type: none;
  padding: 0;

  li {
    float: left;
    padding-right: 20px;
    padding-top: 10px;
  }
}

label.btn {
  margin-bottom: 0;
}
