@import "../../scss/variables.scss";

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    box-shadow: 0px 0px 5px 2px #00000050 inset;
}

.slider.blue:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 0px;
    bottom: 0px;
    background-color: $invevo-background-grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0px 2px 4px #00000050;
    border: 1px solid $invevo-blue;
}

.slider.purple:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 0px;
    bottom: 0px;
    background-color: $invevo-background-grey;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0px 2px 4px #00000050;
    border: 1px solid $invevo-purple;
}

input + .slider {
    background-color: $invevo-background-grey;
}

input:checked + .slider {
    &.blue {
        background-color: $invevo-blue;
    }

    &.purple {
        background-color: $invevo-purple;
    }

    box-shadow: 0px 0px 5px 2px #00000050 inset;
}

input:focus + .slider {
    box-shadow: 0 0 1px $invevo-background-grey;
    box-shadow: 0px 0px 5px 2px #00000050 inset;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.disabled {
    opacity: 0.5;
}
