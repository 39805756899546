@import "../../scss/variables.scss";

.container {
    border-radius: 3px;
    border: 2px solid $invevo-blue;

    &.selected {
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        background-color: $invevo-mid-blue;
    }
}
