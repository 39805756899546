#tasks-list {
  min-height: 75vh;

  #task-date .btn-group {
    width: 100%;
  }

  .section-1 {
    padding-left: 15px;
    width: calc(50% - 15px);
  }

  .section-3 {
    padding-right: 15px;
    width: calc(50% - 15px);
  }

  .section-2 {
    width: 30px;
    text-align: center;
    line-height: 38px;
  }

  .k-grid {
    background-color: $mia-action;
  }
  .k-grid-table {
    border-spacing: 0 15px;
  }
  .k-detail-row {
    .k-hierarchy-cell,
    .k-detail-cell {
      background-color: $white;
    }
  }
  tr:not(.k-state-selected) .k-hierarchy-cell,
  .result-default {
    background-color: $mia-action;
    border: 1px solid $white;
    color: $mia-dark-grey;
    padding-bottom: 15px;
    &:hover {
      filter: grayscale(0.1);
    }
  }

  .k-state-selected .k-hierarchy-cell,
  .result-selected {
    border: 1px solid $white;
    color: $mia-dark-grey;
    background-color: $mia-dark-blue;
    padding-bottom: 15px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.task-list-toolbar {
  padding: 5px 10px;
  border-bottom: 3px solid $mia-action-dark;

  h3 {
    line-height: 38px;
    margin: 0;
  }
}
